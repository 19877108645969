/* eslint-disable multiline-ternary */
import React, { useState } from "react"
import { Row, Col, Grid } from "react-styled-flexboxgrid"
import { useMediaQuery } from "react-responsive"
import { Link } from "react-scroll"

import Logo from "../../components/Logo"
import Button from "../../components/Button"

import { FooterContent } from "../Footer"

import * as S from "./styled"

const links = [
  {
    title: "About",
    url: "about",
  },
  {
    title: "Details",
    url: "details",
  },
  {
    title: "Mangrove Project",
    url: "project",
  },
  {
    title: "Roadmap",
    url: "roadmap",
  },
]

const Header: React.FC = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const [open, setOpen] = useState(false)

  const MenuLinks = () => (
    <S.Menu>
      {links.map((item, i) => (
        <S.MenuItem key={i}>
          <S.MenuLink
            as={Link}
            to={item.url}
            activeClass="active"
            onClick={() => setOpen(false)}
            spy={true}
            smooth={true}
            offset={-60}
            duration={500}
          >
            {item.title}
          </S.MenuLink>
        </S.MenuItem>
      ))}
    </S.Menu>
  )

  return (
    <S.Header>
      <S.Inner>
        <Grid>
          <Row middle="sm">
            <Col xs={6} sm={10}>
              <Row middle="sm">
                <Col>
                  <S.Logo
                    as={Link}
                    to="home"
                    onClick={() => setOpen(false)}
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                  >
                    <Logo />
                  </S.Logo>
                </Col>
                {!isMobile ? (
                  <Col xs>
                    <MenuLinks />
                  </Col>
                ) : null}
              </Row>
            </Col>

            <Col xs={6} sm={2} style={{ textAlign: "right" }}>
              <Button href="https://snapshot.org/#/mangrovedao.eth/" text="Participate" color="violet" />

              {isMobile && (
                <S.MenuButton open={open} onClick={() => setOpen(!open)}>
                  <span />
                  <span />
                  <span />
                  <span />
                </S.MenuButton>
              )}
            </Col>
          </Row>
        </Grid>
      </S.Inner>

      {isMobile ? (
        <S.DropdownMenu open={open}>
          <Row center="xs">
            <Col xs={12} style={{ alignSelf: "center" }}>
              <MenuLinks />
            </Col>
            <Col xs={12} style={{ alignSelf: "end" }}>
              <FooterContent />
            </Col>
          </Row>
        </S.DropdownMenu>
      ) : null}
    </S.Header>
  )
}

export default Header
