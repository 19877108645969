import React from "react"
import { Grid } from "react-styled-flexboxgrid"

import * as S from "./styled"

interface SectionProps {
  as?: any // TODO
  id?: string
}

const Section: React.FC<SectionProps> = ({ children, as, id, ...props }) => {
  return (
    <S.Section as={as} id={id} {...props}>
      <Grid>{children}</Grid>
    </S.Section>
  )
}

export default Section
