const CheckCircle = () => {
  return (
    <svg viewBox="0 0 32 32">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.317 11.8895L16.4794 20.5068C16.355 20.7858 16.1113 20.9917 15.8162 21.0685C15.7791 21.0778 15.7417 21.0851 15.7051 21.0902C15.4437 21.127 15.1767 21.0595 14.9643 20.8985L11.5762 18.3454C11.1348 18.0136 11.0467 17.3868 11.3794 16.9453C11.7113 16.5048 12.3381 16.4167 12.7796 16.7485L15.1636 18.545L18.4902 11.0759C18.7151 10.5717 19.3052 10.3444 19.8104 10.5693C20.3146 10.7943 20.541 11.3855 20.317 11.8895ZM14.3301 4.11774C7.77798 5.03858 3.19691 11.1179 4.11774 17.6699C5.03858 24.222 11.1179 28.8031 17.6699 27.8823C24.222 26.9614 28.8031 20.8821 27.8823 14.3301C26.9614 7.77798 20.8821 3.19691 14.3301 4.11774Z"
      />
    </svg>
  )
}

export default CheckCircle
