/* eslint-disable multiline-ternary */
import React, { useState } from "react"
import { XYPlot, XAxis, YAxis, GradientDefs, makeWidthFlexible, Hint, LineMarkSeries, AreaSeries } from "react-vis"

import * as S from "./styled"

const CHART_HEIGHT = 240
const CHART_MARGIN = 40
const CHART_OPACITY = 0.4

const CHART_PROPS = {
  height: CHART_HEIGHT,
  margin: {
    right: CHART_MARGIN,
    left: CHART_MARGIN,
  },
}

interface DataProps {
  data: any
  dataIssued: any
}

export const Graph: React.FC<DataProps> = ({ data, dataIssued }) => {
  const [chartHintValue, setChatHintValue] = useState(null)
  const [chartType, setChatType] = useState("initial")

  const FlexibleXYPlot = makeWidthFlexible(XYPlot)

  const rememberChartHintValue = (value: any, graph: string) => {
    setChatHintValue(value)
    setChatType(graph)
  }
  const forgetChartHintValue = () => {
    setChatHintValue(null)
    setChatType("initial")
  }

  const axisStyle = {
    ticks: {
      fontSize: "14px",
      color: "#333",
    },
    title: {
      fontSize: "16px",
      color: "#333",
    },
  }

  const firstDate = data[0].x
  const endDate = data[data.length - 1].x

  return (
    <S.Graph>
      <FlexibleXYPlot xType="linear" align="left" {...CHART_PROPS} onMouseLeave={forgetChartHintValue}>
        <GradientDefs>
          <linearGradient id="AreaGradient" x1="0" x2="0" y1="0" y2="1">
            <stop offset="0%" stopColor={"#78BFB6"} stopOpacity={CHART_OPACITY} />
            <stop offset="100%" stopColor="#fff" stopOpacity={CHART_OPACITY} />
          </linearGradient>
        </GradientDefs>

        {/* <VerticalGridLines /> */}

        <YAxis hideLine attr="y" hideTicks attrAxis="x" orientation="left" style={axisStyle} />
        <XAxis
          hideLine
          attr="x"
          attrAxis="y"
          orientation="bottom"
          tickValues={[firstDate, endDate]}
          tickFormat={(t) => t}
        />
        <AreaSeries data={data} color="url(#AreaGradient)" />
        <LineMarkSeries
          onValueMouseOver={(value) => rememberChartHintValue(value, "initial")}
          onValueMouseOut={forgetChartHintValue}
          data={data}
          strokeWidth={0}
          stroke="#78BFB6"
          lineStyle={{ stroke: "#78BFB6", strokeWidth: 4 }}
          markStyle={{ stroke: "", fill: "#78BFB6" }}
        />

        <LineMarkSeries
          onValueMouseOver={(value) => rememberChartHintValue(value, "issued")}
          onValueMouseOut={forgetChartHintValue}
          data={dataIssued}
          strokeWidth={0}
          stroke="#E85531"
          lineStyle={{ stroke: "#E85531", strokeWidth: 4 }}
          markStyle={{ stroke: "", fill: "#E85531" }}
        />
        {chartHintValue ? (
          <Hint value={chartHintValue} align={{ vertical: "top", horizontal: "auto" }}>
            <S.Hint>
              <h4>{new Intl.NumberFormat().format(chartHintValue?.y)}</h4>
              <p>
                {chartType === "initial" ? "Validated" : "Issued"} in {chartHintValue?.x}
              </p>
            </S.Hint>
          </Hint>
        ) : null}
      </FlexibleXYPlot>
    </S.Graph>
  )
}
