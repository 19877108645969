import styled from "styled-components"
import { rem, rgba } from "polished"

export const Wrapper = styled.section``

export const Title = styled.h2`
  margin-bottom: ${rem(68)};
  text-align: center;

  @media all and (max-width: 767px) {
    margin-bottom: 25px;
  }
`

export const Image = styled.img`
  width: ${rem(40)};
  margin: 0 auto ${rem(27)};
  display: block;
`

export const Logo = styled.div`
  margin: 0 auto ${rem(30)};
  display: flex;
  justify-content: center;
`

export const Col = styled.div`
  margin-bottom: 12px;

  @media all and (min-width: 992px) {
    margin-bottom: ${rem(36)};
  }
`

export const Grid = styled.div`
  @media all and (min-width: 992px) {
    display: grid;
    grid-template-columns: auto auto auto;
  }
`

export const Content = styled.div`
  --indent: ${rem(32)};
  height: 100%;

  padding: ${rem(14)} var(--indent);
  position: relative;

  @media all and (max-width: 767px) {
    padding: 6px 0;
  }
`

export const Back = styled.div`
  --indent: ${rem(32)};

  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;

  &::after {
    content: "";
    position: absolute;
    left: var(--indent);
    right: var(--indent);
    bottom: 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray};
  }

  @media all and (max-width: 767px) {
    --indent: 0;
    z-index: auto;

    &::after {
      left: var(--indent);
      right: var(--indent);
    }
  }
`

export const Col1 = styled.td`
  width: ${rem(400)};
  text-align: center;

  h4 {
    color: ${(props) => props.theme.colors.secondaryDark};
  }

  ${Back} {
    background-color: ${(props) => props.theme.colors.secondary};

    &:after {
      border-bottom-color: ${(props) => rgba(props.theme.colors.secondaryDark, 0.2)};
    }
  }

  @media all and (max-width: 767px) {
    width: 100%;
  }
`

export const Col2 = styled(Col1)`
  position: relative;

  h4 {
    color: ${(props) => props.theme.colors.primaryDark};
  }

  ${Back} {
    background-color: ${(props) => props.theme.colors.primaryLight};

    &:after {
      border-bottom-color: ${(props) => rgba(props.theme.colors.primaryDark, 0.2)};
    }
  }
`

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin-bottom: ${rem(32)};

  tbody {
    font-size: ${(props) => rem(props.theme.fontSize.supporting)};
    line-height: ${(props) => rem(props.theme.lineHeight.supporting)};
    text-transform: uppercase;
    letter-spacing: ${rem(1)};
  }

  th,
  td {
    padding: 0 ${rem(20)};
    position: relative;
    vertical-align: top;
  }

  tr:last-child {
    ${Back} {
      height: 130%;
      border-radius: 0 0 ${rem(20)} ${rem(20)};

      &::after {
        display: none;
      }
    }
  }

  th {
    padding: ${rem(40)} ${rem(80)} ${rem(28)};

    ${Back} {
      border-radius: ${rem(20)} ${rem(20)} 0 0 !important;
    }
  }

  h4 {
    position: relative;
    margin-bottom: 0;
  }

  a,
  button {
    margin: 0 auto;
    padding: 0;
    line-height: normal;
    vertical-align: top;

    .icon {
      margin-top: -5px;
      margin-bottom: -5px;
    }
  }

  @media all and (max-width: 767px) {
    table-layout: fixed;

    tbody {
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 1px;
      text-align: left;
    }

    th,
    td {
      padding: 0;
      text-align: left;
    }

    th {
      padding: 0 0 10px;
    }

    h4 {
      font-size: 17px;
      line-height: 24px;
    }

    button,
    a {
      vertical-align: middle;
    }
  }
`

export const TitleCol = styled.td`
  position: relative;
  width: 200px;
  white-space: nowrap;
  padding-left: 0 !important;
`

export const BlankCol = styled.td`
  width: 0;
  padding: 0 ${rem(20)} !important;
`
