import React from "react"

import Layout from "../core/Layout"

import Main from "../components/Main"
import SectionLead from "../containers/SectionLead"
import SectionAbout from "../containers/SectionAbout"
import SectionDetails from "../containers/SectionDetails"
import SectionSpecification from "../containers/SectionSpecification"
import SectionPartners from "../containers/SectionPartners"
import SectionRoadmap from "../containers/SectionRoadmap"
import SectionProjects from "../containers/SectionProjects"

const Home: React.FC = () => {
  return (
    <Layout>
      <Main>
        <SectionLead />
        <SectionAbout />
        <SectionDetails />
        <SectionSpecification />
        <SectionProjects />
        <SectionRoadmap />
        <SectionPartners />
      </Main>
    </Layout>
  )
}

export default Home
