import React from "react"
import { Row, Col } from "react-styled-flexboxgrid"
import { useMediaQuery } from "react-responsive"

import { CounterBlock } from "./counter"
import { Graph } from "./graph"

import { withPrefix } from "gatsby"

import { Lead } from "../../components/Typography"
import Section from "../../components/Section"
import Button from "../../components/Button"
import Icon from "../../components/Icon"
import Card from "../../components/Card"

import { useInView } from "react-intersection-observer"
import { FadeIn } from "../../components/Animate"

import * as S from "./styled"

const data = [
  { x: 2015, y: 7521 },
  { x: 2016, y: 13982 },
  { x: 2017, y: 35161 },
  { x: 2018, y: 51315 },
  { x: 2019, y: 63447 },
  { x: 2020, y: 96668 },
  { x: 2021, y: 118828 },
  { x: 2022, y: 172444 },
  { x: 2023, y: 215732 },
  { x: 2024, y: 250148 },
  { x: 2025, y: 290429 },
  { x: 2026, y: 310907 },
  { x: 2027, y: 329081 },
  { x: 2028, y: 337240 },
  { x: 2029, y: 342849 },
  { x: 2030, y: 306848 },
  { x: 2031, y: 227274 },
  { x: 2032, y: 199766 },
  { x: 2033, y: 156895 },
  { x: 2034, y: 153591 },
]

const dataIssued = [
  { x: 2017, y: 31744 },
  { x: 2018, y: 26615 },
  { x: 2019, y: 53369 },
  { x: 2020, y: 54137 },
]

const SectionProjects: React.FC = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const [refContent, inViewContent] = useInView({ triggerOnce: true })
  const [refContentBottom, inViewContentBottom] = useInView({ triggerOnce: true })

  return (
    <Section as={S.Wrapper} id="project">
      <S.Card as={Card} size="md" color="green" align="center">
        <S.Row as={Row} middle="xs" ref={refContent}>
          <S.Col as={Col} xs={12} sm={7}>
            <FadeIn $animate={inViewContent} $delay={0}>
              <S.Title>Mangrove Project</S.Title>
            </FadeIn>
            <FadeIn $animate={inViewContent} $delay={1}>
              <Lead>
                The project replants severely degraded mangroves in the Ayeyarwady region of Myanmar. The project's
                developer is Worldview International Foundation, one of the world's most respected eco heroes. Founded
                in 1979, it established national parks in Myanmar and Sri Lanka, planted tens of millions of trees, and
                provided hundreds of jobs on a regular basis.
              </Lead>
            </FadeIn>
            <FadeIn $animate={inViewContent} $delay={2}>
              <p>
                To ensure the project's long term sustainability, WIF uses a community-based model to educate and employ
                locals, to provide scholarships and help schools, to support families and gender equality.
              </p>
            </FadeIn>
            <FadeIn $animate={inViewContent} $delay={3}>
              <S.Button>
                <Button
                  text="Verra Project page"
                  href="https://registry.verra.org/app/projectDetail/VCS/1764"
                  icon={<Icon iconKey="arrowRight" />}
                  variant="text"
                  color="green"
                />
              </S.Button>
            </FadeIn>
          </S.Col>
          {!isMobile && (
            <S.Col as={Col} xs={12} sm={5}>
              <S.Image src={withPrefix("/images/carbon-projects.png")} />
            </S.Col>
          )}
        </S.Row>
        <S.Card as={Card} size="md" align="center">
          <h5>Project carbon curve</h5>
          <CounterBlock />

          {!isMobile && <Graph data={data} dataIssued={dataIssued} />}
        </S.Card>

        <S.Group ref={refContentBottom}>
          <FadeIn $animate={inViewContentBottom} $delay={1}>
            <p>
              The confirmed commitment from WIF is to pledge 8% of all the total carbon yield value (post Verra buffer)
              to Mangrove DAO starting from the 2021 vintage. 4331 tonnes were transferred in 2022, out of which 4200
              tonnes were{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://polygonscan.com/token/0xfbc5d9620b6bb729be2f72fca157054bd3da3d1a?a=0xF737f8B819869c3216C8ea33CdA38D8ED829e9F1"
              >
                tokenized
              </a>{" "}
              (subject to 3% tokenization fee)
            </p>
          </FadeIn>
          <FadeIn $animate={inViewContentBottom} $delay={2}>
            <S.Button>
              <Button
                text="Public Pledge"
                href={withPrefix("/uploads/WIF-TREE-pledge.pdf")}
                icon={<Icon iconKey="arrowRight" />}
                variant="text"
                color="green"
              />
            </S.Button>
          </FadeIn>
        </S.Group>
      </S.Card>
    </Section>
  )
}

export default SectionProjects
