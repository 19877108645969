import React from "react"
import styled, { css } from "styled-components"

interface LogoProps {
  type?: "accent" | "normal" | "mono"
  size?: string | number
}

const Wrapper = styled.div<LogoProps>`
  font-size: 0;
  display: flex;

  ${(props) =>
    props.type === "accent" &&
    css`
      .logo-circle {
        fill: ${(props) => props.theme.colors.primary};
      }
      .logo-figure {
        fill: ${(props) => props.theme.colors.primaryDark};
      }
    `}

  @media all and (max-width: 767px) {
    width: 32px;
    display: block;
    line-height: 0;
    font-size: 0;

    svg {
      height: auto;
      width: 100%;
    }
  }
`

const Logo: React.FC<LogoProps> = ({ type, size = 40, ...rest }) => {
  return (
    <Wrapper type={type} {...rest} className="logo-svg">
      <svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          className="logo-circle"
          d="M20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40Z"
          fill="#C6B4FF"
        />
        <path
          className="logo-figure"
          d="M12.2033 25.2085C12.6525 25.2299 13.0696 25.0695 13.3904 24.7914C13.5188 24.6738 13.4653 24.4706 13.3049 24.4278C11.7755 24.0321 10.4706 23.0374 9.70059 21.647C9.43321 21.6898 9.15513 21.7005 8.87706 21.6898C8.18187 21.6577 7.52946 21.4652 6.96262 21.1658C6.86636 21.1123 6.73802 21.1444 6.68454 21.2513C6.44925 21.6684 6.3316 22.1604 6.37438 22.6845C6.45994 23.8823 7.37973 24.8877 8.57759 25.0588C9.4439 25.1871 10.246 24.877 10.8022 24.3208C11.0696 24.8235 11.5936 25.1871 12.2033 25.2085Z"
          fill="white"
        />
        <path
          className="logo-figure"
          d="M10.4171 9.46522C10.492 9.46522 10.5669 9.46522 10.6417 9.46522C12.0642 9.52939 13.3583 10.2781 14.1498 11.4331C15.1658 10.8128 16.3423 10.5133 17.5615 10.5668C17.8396 10.5775 18.1177 10.6203 18.385 10.6631C18.4492 10.6738 18.5134 10.6631 18.5776 10.631C19.3155 10.1818 19.8075 9.35827 19.7647 8.3957C19.7113 7.32618 18.9412 6.4064 17.9038 6.13902C17.3583 5.99998 16.8342 6.05346 16.3744 6.24597C16 5.7433 15.4118 5.40105 14.738 5.36896C13.6685 5.31549 12.7487 6.05346 12.5241 7.0695C12.3744 7.02672 12.2139 6.98394 12.0535 6.98394C11.0268 6.94116 10.1604 7.7326 10.107 8.75934C10.0963 8.94116 10.1177 9.11228 10.1604 9.27271C10.1818 9.39035 10.2888 9.47592 10.4171 9.46522Z"
          fill="white"
        />
        <path
          className="logo-figure"
          d="M20.6845 8.69517C20.6418 9.60426 20.246 10.4278 19.5936 11.0267C21.8824 11.9786 23.4653 14.2781 23.3369 16.8984C23.3049 17.6791 23.1123 18.4278 22.8022 19.1123C22.7487 19.2299 22.7808 19.4331 22.845 19.6791C22.8984 19.893 22.8984 20.0855 22.8984 20.1711C22.8664 21.5401 22.353 22.3636 21.9038 22.8235C21.8289 22.8984 21.7968 23.0053 21.8075 23.1123C21.861 23.5401 21.8931 23.9786 21.9145 24.4064C21.9145 24.5989 22.1284 24.7059 22.2888 24.6096C22.5669 24.4278 22.8343 24.2139 23.0803 23.9572C23.615 23.4117 24.2139 22.524 24.4385 21.1978C25.1765 21.647 26.0535 21.893 26.9947 21.8716C29.1765 21.8075 31.0375 20.2032 31.4332 18.0535C31.5829 17.2299 31.5081 16.4385 31.2621 15.7112C32.0214 14.7593 32.4599 13.5401 32.3958 12.1925C32.2781 9.62565 30.2888 7.48661 27.7434 7.18715C26.8771 7.08019 26.0321 7.19784 25.2728 7.48661C24.6845 6.95185 23.9038 6.60961 23.0482 6.56682C22.1498 6.52404 21.3156 6.82351 20.6631 7.33688C20.5776 7.40105 20.5348 7.5187 20.5669 7.62565C20.6525 7.97859 20.6952 8.33153 20.6845 8.69517Z"
          fill="white"
        />
        <path
          className="logo-figure"
          d="M35.0696 15.1764C35.4867 14.8342 35.7541 14.3101 35.722 13.7112C35.6792 12.9839 35.1658 12.3422 34.4599 12.139C34.1284 12.0428 33.8075 12.0535 33.5188 12.1283C33.4011 12.1604 33.3156 12.2674 33.3263 12.385C33.3263 12.5027 33.3263 12.6203 33.3263 12.7379C33.2728 13.8716 32.9198 14.9625 32.2888 15.9037C32.4172 16.4171 32.4706 16.9412 32.4386 17.4759C32.4279 17.7005 32.3958 17.9251 32.3637 18.1497C33.0268 18.3101 33.5722 18.7486 33.8824 19.3262C33.9466 19.4438 34.0749 19.4973 34.2033 19.4545C35.1551 19.123 35.8503 18.2353 35.9038 17.1658C35.9252 16.385 35.6043 15.6684 35.0696 15.1764Z"
          fill="white"
        />
        <path
          className="logo-figure"
          d="M32.3636 19.123C32.2353 19.0695 32.0963 19.1337 32.0428 19.262C31.1872 21.4224 29.0374 22.9198 26.5882 22.8021C26.0321 22.7807 25.4866 22.6631 24.984 22.4813C24.8128 22.4171 24.6417 22.5454 24.6417 22.7273C24.7059 23.9893 25.7112 25.016 26.9947 25.0695C27.9893 25.1123 28.8663 24.5668 29.2941 23.7433C29.6685 24.0962 30.1925 24.3101 30.7808 24.2353C31.4866 24.1497 32.0749 23.6577 32.2995 22.9839C32.4385 22.5668 32.4171 22.1604 32.2888 21.7968C32.8128 21.6043 33.2086 21.1123 33.23 20.5134C33.262 19.893 32.8984 19.3476 32.3636 19.123Z"
          fill="white"
        />
        <path
          className="logo-figure"
          d="M28.3851 32.7914C24.5348 32.7807 21.7434 30.8235 20.7059 27.4331C20.6845 27.3476 20.6738 27.2513 20.6952 27.1658C20.8022 26.6952 20.877 26.2139 20.9305 25.754C21.0802 24.1283 20.8663 22.3957 20.2781 20.7914C21.5081 19.9037 22.3316 18.492 22.4065 16.8663C22.5348 14.0321 20.3423 11.6363 17.5188 11.508C16.0963 11.4438 14.7915 11.9679 13.8182 12.8556C13.369 11.4973 12.1177 10.4813 10.599 10.4171C8.63105 10.3315 6.9626 11.8502 6.87704 13.8182C6.86634 14.0214 6.87704 14.2139 6.89843 14.4064C6.10699 15.0267 5.58292 15.9679 5.52945 17.0481C5.44388 19.016 6.9626 20.6845 8.93052 20.77C9.39041 20.7914 9.82891 20.7273 10.2353 20.5882C10.8984 22.3315 12.5455 23.615 14.5348 23.7005C14.5883 23.7005 14.6417 23.7005 14.6952 23.7005C15.1979 25.0588 16.2567 26.2139 17.9359 26.6845C18.0749 26.7273 18.2139 26.6417 18.246 26.5027C18.3316 26.1069 18.3744 25.7647 18.4065 25.5187C18.4065 25.4759 18.4171 25.4331 18.4171 25.3904C18.4278 25.2406 18.3316 25.1016 18.1819 25.0588C17.2835 24.7486 16.6952 24.1497 16.353 23.4438C17.3476 23.0909 18.2032 22.4278 18.7915 21.5508C18.8022 21.5508 18.8236 21.5401 18.8343 21.5401C19.2942 22.8663 19.4546 24.2888 19.3262 25.615C19.0054 29.0802 16.7166 32.7914 11.2835 32.8128C10.845 32.8128 10.4706 33.1337 10.4385 33.5722C10.4065 34.0535 10.7808 34.4492 11.2514 34.4492C15.7327 34.4385 18.4492 32.246 19.8289 29.5829C21.4225 32.6524 24.5027 34.4385 28.4171 34.4492C28.8877 34.4492 29.2621 34.0535 29.23 33.5722C29.1979 33.123 28.8129 32.7914 28.3851 32.7914Z"
          fill="white"
        />
      </svg>
    </Wrapper>
  )
}

export default Logo
