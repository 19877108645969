import styled from "styled-components"
import { rem } from "polished"

export const Lead = styled.p`
  font-size: ${(props) => rem(props.theme.fontSize.lead)};
  line-height: ${(props) => rem(props.theme.lineHeight.lead)};
  font-weight: ${(props) => props.theme.fontWeight.light};
  margin-bottom: ${rem(30)};

  & + & {
    margin-top: ${rem(-10)};
  }

  @media all and (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 27px;
  }
`

export const Supporting = styled.div`
  font-size: ${(props) => rem(props.theme.fontSize.supporting)};
  line-height: ${(props) => rem(props.theme.lineHeight.supporting)};
  text-transform: uppercase;
  letter-spacing: ${rem(1)};

  @media all and (max-width: 767px) {
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1px;
  }
`
