import styled from "styled-components"
import { rem } from "polished"
import { withPrefix } from "gatsby"

export const Wrapper = styled.section`
  h4 {
    color: ${(props) => props.theme.colors.primaryDark};
    font-feature-settings: "pnum" on, "lnum" on;
    margin-bottom: 0;
  }

  @media all and (min-width: 768px) {
    padding-bottom: 0;
  }
`

export const Title = styled.h2`
  margin-bottom: ${rem(70)};
  text-align: center;

  @media all and (max-width: 767px) {
    margin-bottom: 22px;
  }
`

export const Image = styled.img`
  max-width: 100%;
  display: block;
`

export const Col = styled.div`
  margin-bottom: 20px;

  @media all and (min-width: 992px) {
    margin-bottom: ${rem(45)};
  }
`

export const Group = styled.div`
  max-width: ${rem(840)};
  margin-left: auto;
  margin-right: auto;
`

export const List = styled.ul`
  --size: ${rem(20)};
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin-bottom: ${rem(19)};
    position: relative;
    padding-left: ${rem(40)};

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: ${rem(7)};
      width: var(--size);
      height: var(--size);
      background: url(${withPrefix("/images/point.svg")}) no-repeat center / var(--size) var(--size);
    }

    a {
      border-bottom: 1px solid ${(props) => props.theme.colors.primary};

      &:hover {
        border-color: transparent;
      }
    }

    @media all and (max-width: 767px) {
      --size: 10px;
      padding-left: 20px;

      &:before {
        width: var(--size);
        height: var(--size);
        top: 5px;
        background-size: var(--size) var(--size);
      }
    }
  }
`
