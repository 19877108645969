import React, { ReactNode } from "react"
import * as I from "../icons"
import styled from "styled-components"
import { rem } from "polished"

/**
 * Types
 */
export const IconKeyVariants = [
  "arrowRight",
  "arrowLeft",
  "check",
  "checkCircle",
  "infoCircle",
  "link",
  "close",
  "instagram",
  "linkedIn",
  "medium",
  "reddit",
  "telegram",
  "twitter",
  "youTube",
  "fail",
  "helpCircle",
  "facebook",
  "discord",
  "menu",
] as const

export type IconKeyVariant = typeof IconKeyVariants[number]

export const IconSizeVariants = ["xxs", "xs", "sm", "md", "lg", "xlg", "xxlg"] as const

export type IconSizeVariant = typeof IconSizeVariants[number]
export interface IconProps {
  iconKey: IconKeyVariant
  className?: string
  size?: IconSizeVariant
  rotate?: number
}
export interface IconStyleProps {
  size?: IconSizeVariant
  rotate?: number
}

/**
 * Constants
 */
export const iconMap: Record<IconKeyVariant, ReactNode> = {
  checkCircle: <I.CheckCircle />,
  infoCircle: <I.InfoCircle />,
  helpCircle: <I.HelpCircle />,
  twitter: <I.Twitter />,
  telegram: <I.Telegram />,
  medium: <I.Medium />,
  instagram: <I.Instagram />,
  linkedIn: <I.LinkedIn />,
  reddit: <I.Reddit />,
  youTube: <I.YouTube />,
  link: <I.Link />,
  fail: <I.Fail />,
  check: <I.Check />,
  close: <I.Close />,
  arrowLeft: <I.ArrowLeft />,
  arrowRight: <I.ArrowRight />,
  facebook: <I.Facebook />,
  discord: <I.Discord />,
  menu: <I.Menu />,
}

export const iconSizeMap: Record<IconSizeVariant, number> = {
  xxs: 20,
  xs: 24,
  sm: 32,
  md: 40,
  lg: 72,
  xlg: 80,
  xxlg: 116,
}

export const iconMobileSizeMap: Record<IconSizeVariant, number> = {
  xxs: 12,
  xs: 12,
  sm: 12,
  md: 16,
  lg: 28,
  xlg: 72,
  xxlg: 80,
}

/**
 * Styles
 */
export const StyledIcon = styled.i<IconStyleProps>`
  display: inline-block;
  vertical-align: middle;
  height: ${(props) => rem(iconSizeMap[props.size ?? "sm"])};
  text-align: center;
  color: inherit;
  font-family: inherit !important;

  &:before {
    display: none !important;
  }

  svg {
    display: inline-block;
    vertical-align: top;
    height: 100%;
    color: inherit;
    transform: ${(props) => `rotate(${props.rotate ?? 0}deg)`};
  }

  @media all and (max-width: 767px) {
    width: ${(props) => iconMobileSizeMap[props.size ?? "sm"]}px;
    height: ${(props) => iconMobileSizeMap[props.size ?? "sm"]}px;

    svg {
      width: 100%;
    }
  }
`
/**
 * Main
 */
const Icon: React.FC<IconProps> = ({ iconKey, className, size, rotate, ...props }) => {
  const stylingProps = { size, rotate }

  return (
    <StyledIcon className={`icon icon--${iconKey} ${className ?? ""}`} {...stylingProps} {...props}>
      {iconMap[iconKey]}
    </StyledIcon>
  )
}

export default Icon
