import React from "react"

import * as S from "./styled"

/**
 * Types
 */
interface ButtonProps {
  text: React.ReactNode | string
  color?: S.Color
  icon?: React.ReactNode | string | null
  textFirst?: boolean
  variant?: S.Variant
  layout?: S.Layout
  href?: string | null
  fullWidth?: boolean
  onClick?: Function | null
  as?: any // TODO
}

const Button: React.FC<ButtonProps> = ({
  text,
  variant = "primary",
  icon = null,
  textFirst = false,
  color = "transparent",
  layout = "initial",
  href = null,
  fullWidth = false,
  onClick = null,
  as = "button",
  ...rest
}: ButtonProps) => {
  const handleOnClick = (): void => {
    if (!onClick) return
    onClick()
  }

  return (
    <S.Main
      variant={variant}
      color={color}
      layout={layout}
      $icon={icon !== null}
      $textFirst={textFirst}
      $fullWidth={fullWidth}
      {...(href && {
        as: "a",
        href: href,
        target: "_blank",
        rel: "noopener noreferrer",
      })}
      onClick={handleOnClick}
      as={!href ? as : "a"}
      {...rest}
    >
      {icon}
      {text && <span>{text}</span>}
    </S.Main>
  )
}

export default Button
