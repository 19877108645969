const Telegram = () => {
  return (
    <svg viewBox="0 0 32 32">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.401 26.4535L26.5738 5.82695C26.6812 5.29504 26.1727 4.85128 25.6797 5.04717L2.42776 14.2653C1.85388 14.4924 1.85879 15.3274 2.43431 15.549L8.09997 17.7219L10.2929 24.9696C10.4333 25.4335 10.9856 25.6051 11.3521 25.2971L14.5103 22.6515C14.8413 22.3741 15.3129 22.3604 15.6587 22.6184L21.3549 26.8684C21.747 27.1612 22.3027 26.9405 22.401 26.4535ZM9.93941 16.5653L21.0122 9.557C21.2111 9.43147 21.4159 9.70797 21.2448 9.87081L12.1067 18.6001C11.7854 18.9073 11.5783 19.3187 11.5195 19.765L11.2084 22.1357C11.1669 22.4521 10.7345 22.4834 10.6493 22.1772L9.45218 17.8542C9.31526 17.3611 9.51501 16.8344 9.93941 16.5653Z"
      />
    </svg>
  )
}

export default Telegram
