import styled, { css } from "styled-components"

export const FadeIn = styled.div<{ $animate?: boolean; $delay?: number }>`
  will-change: transform, opacity;
  transition: transform 1s ease, opacity 1s ease;
  transform: translate3d(0, 55px, 0);
  opacity: 0;

  ~ .connector:after {
    opacity: 0;
    transition: transform 1s ease, opacity 3s ease;
  }

  ${(props) =>
    props.$animate &&
    css`
      transform: translate3d(0, 0, 0);
      opacity: 1;

      ~ .connector:after {
        opacity: 1;
      }
    `}

  ${(props) =>
    props.$delay &&
    css`
      transition-delay: ${`0.${props.$delay}s`};

      ~ .connector:after {
        transition-delay: ${`0.2${props.$delay}s`};
      }
    `}
`
