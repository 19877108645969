import styled, { css } from "styled-components"
import { rem } from "polished"
import { Row } from "react-styled-flexboxgrid"

export const Header = styled.header<{ open?: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  font-size: ${(props) => rem(props.theme.fontSize.supporting)};
  line-height: ${(props) => rem(props.theme.lineHeight.supporting)};
  font-weight: ${(props) => props.theme.fontWeight.base};
  text-transform: uppercase;
  z-index: 100;
  background-color: ${(props) => props.theme.colors.white};

  ${Row} {
    position: relative;
    z-index: 100;
  }
`

export const Menu = styled.div`
  display: flex;
  letter-spacing: ${rem(1)};

  @media all and (max-width: 767px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 20px;
  }
`

export const Inner = styled.div`
  position: relative;
  z-index: 100;
  width: 100%;
  padding: ${rem(22)} 0;
  background-color: ${(props) => props.theme.colors.white};

  @media all and (max-width: 767px) {
    padding: 16px 0;

    ${Menu} {
      display: none !important;
    }
  }
`

export const MenuItem = styled.div`
  @media all and (max-width: 767px) {
    width: 100%;
  }
`

export const MenuLink = styled.a<any>`
  padding: ${rem(10)} ${rem(20)};
  cursor: pointer;

  &.active {
    color: ${(props) => props.theme.colors.primaryDark};
  }

  @media all and (max-width: 767px) {
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.25px;
    padding: 12px 20px;
    text-transform: none;
    display: block;
    font-family: ${(props) => props.theme.font.headings};
    color: ${(props) => props.theme.colors.black} !important;

    &.active,
    &:hover {
      background-color: #f0f0f0;
      border-radius: 12px;
    }
  }
`

export const DropdownMenu = styled.div<{ open?: boolean }>`
  position: fixed;
  overflow: hidden;
  opacity: 0;
  height: 0;
  top: 0;
  width: 100%;
  z-index: 99;
  text-align: center;
  text-transform: none;
  transition: all 0.1s ease;
  padding: 16px;
  will-change: opacity, transform, top;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  font-weight: 300;

  > ${Row} {
    height: 100%;
  }

  ${(props) =>
    props.open &&
    css`
      transition: all 0.3s ease;
      opacity: 1;
      height: calc(100vh - 60px);
      top: 60px;
      background-color: ${(props) => props.theme.colors.white};
    `}
`

export const Logo = styled.div<any>`
  font-size: 0;
  line-height: 0;
  cursor: pointer;
  display: block;

  @media all and (max-width: 767px) {
    width: 28px;

    svg {
      width: 100%;
      height: auto;
    }

    > div {
      width: 100%;
    }
  }
`

export const MenuButton = styled.button<{ onClick?: Function | null; open?: boolean }>`
  border: 0;
  appearance: none;
  background: transparent;
  width: 28px;
  height: 28px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  padding: 5px;
  border: 1px solid ${(props) => props.theme.colors.black};
  border-radius: 100px;
  display: inline-flex;
  vertical-align: middle;
  margin-left: 8px;

  span {
    display: block;
    position: absolute;
    height: 1px;
    width: 10px;
    left: 50%;
    margin-left: -5px;
    background-color: ${(props) => props.theme.colors.black};
    border-radius: 9px;
    opacity: 1;
    transform: rotate(0deg);
    transition: all 0.3s ease;

    &:nth-child(1) {
      top: 10px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 13px;
    }

    &:nth-child(4) {
      top: 16px;
    }
  }

  ${(props) =>
    props.open &&
    css`
      background-color: ${(props) => props.theme.colors.black};
      span {
        background-color: ${(props) => props.theme.colors.white};

        &:nth-child(1) {
          top: 13px;
          width: 0;
          left: 50%;
          margin-left: 0;
        }

        &:nth-child(2) {
          transform: rotate(45deg);
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
        }

        &:nth-child(4) {
          top: 13px;
          width: 0;
          left: 50%;
          margin-left: 0;
        }
      }
    `}
`
