import React from "react"
import { Grid, Row, Col } from "react-styled-flexboxgrid"
import { useMediaQuery } from "react-responsive"

import Icon, { IconKeyVariant } from "../../components/Icon"

import { CURRENT_YEAR, SOCIAL_LINKS } from "../../utils/const"

import * as S from "./styled"

export const FooterContent = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 })

  return (
    <Row between="xs">
      <Col xs={12} sm={6}>
        © {CURRENT_YEAR} Mangrove DAO. {isMobile && <br />} All rights reserved.
      </Col>
      <Col xs={12} sm={6}>
        <S.Social>
          {SOCIAL_LINKS.map((item, i) => (
            <a href={item.url} key={i} title={item.name}>
              <Icon iconKey={item.icon as IconKeyVariant} size="sm" />
            </a>
          ))}
        </S.Social>
      </Col>
    </Row>
  )
}

const Footer: React.FC = () => {
  return (
    <S.Footer>
      <Grid>
        <FooterContent />
      </Grid>
    </S.Footer>
  )
}

export default Footer
