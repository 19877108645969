import React from "react"

import * as S from "./styled"

interface CardProps {
  size?: S.Size
  color?: S.Color
  align?: S.Align
}

const Card: React.FC<CardProps> = ({ children, size = "sm", color = "transparent", align = "left", ...rest }) => {
  return (
    <S.Card $size={size} $color={color} $align={align} {...rest}>
      {children}
    </S.Card>
  )
}

export default Card
