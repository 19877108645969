import React from "react"
import { withPrefix } from "gatsby"
import { Row, Col } from "react-styled-flexboxgrid"

import Section from "../../components/Section"
import Card from "../../components/Card"
import Button from "../../components/Button"
import Icon from "../../components/Icon"
import { Lead } from "../../components/Typography"
import Logo from "../../components/Logo"

import { useInView } from "react-intersection-observer"
import { FadeIn } from "../../components/Animate"

import * as S from "./styled"

const SectionLead: React.FC = () => {
  const { ref, inView } = useInView({ triggerOnce: true })

  return (
    <Section as={S.Wrapper} id="home">
      <Card align="center" size="lg" color="violet">
        <S.Group ref={ref}>
          <FadeIn $animate={inView} $delay={1}>
            <S.Title>
              Mangrove DAO{" "}
              <S.Image as="span">
                <Logo type="accent" size={72} />
              </S.Image>{" "}
              is focused on restoration of degraded mangrove{" "}
              <S.Image src={withPrefix("/images/mangrove.png")} alt="mangroves" /> habitats in Myanmar{" "}
              <S.Image src={withPrefix("/images/myanmar.png")} alt="myanmar" /> and globally
            </S.Title>
          </FadeIn>

          <FadeIn $animate={inView} $delay={2}>
            <Lead>
              Mangrove DAO is a Decentralized Autonomous Organization. It implements novel governance mechanisms to
              empower the community of TREE coin holders.
            </Lead>
          </FadeIn>

          <Row center="xs">
            <Col>
              <FadeIn $animate={inView} $delay={4}>
                <Button
                  text="Read the full TREE coin story"
                  href="https://medium.com/@mangrovedao/tree-coin-story-2bce6723d496"
                  icon={<Icon iconKey="arrowRight" />}
                  variant="text"
                  color="violet"
                />
              </FadeIn>
            </Col>
            <Col />
            <Col>
              <FadeIn $animate={inView} $delay={5}>
                <Button
                  text="Wif Commitment"
                  href={withPrefix("/uploads/WIF-TREE-pledge.pdf")}
                  icon={<Icon iconKey="arrowRight" />}
                  variant="text"
                  color="violet"
                />
              </FadeIn>
            </Col>
            <Col xs={12} />
            <Col>
              <FadeIn $animate={inView} $delay={6}>
                <Button
                  text="How to hold tokens?"
                  href={"https://medium.com/@mangrovedao/how-to-hold-tokes-on-polygon-wallet-a900394668f3"}
                  icon={<Icon iconKey="arrowRight" />}
                  variant="text"
                  color="violet"
                />
              </FadeIn>
            </Col>
            <Col>
              <FadeIn $animate={inView} $delay={6}>
                <Button
                  text="How to trade MANGROVE tokens?"
                  href={"https://medium.com/@mangrovedao/how-to-trade-mangrove-dao-tokens-on-sushiswap-9c23bf04aba4"}
                  icon={<Icon iconKey="arrowRight" />}
                  variant="text"
                  color="violet"
                />
              </FadeIn>
            </Col>
          </Row>
        </S.Group>
      </Card>
    </Section>
  )
}

export default SectionLead
