import styled, { css } from "styled-components"
import { rem } from "polished"

/**
 * Types
 */
export type Variant = "primary" | "text"
export type Color = "green" | "violet" | "red" | "transparent" | "inverse"
export type Layout = "initial" | "space-between" | "center"

interface ButtonProps {
  variant: Variant
  color: Color
  layout: Layout
  $icon: boolean
  $textFirst: boolean
  $fullWidth: boolean
}

export const Icon = styled.img`
  &:only-child {
    margin-right: 0;
  }
`

export const Main = styled.button<ButtonProps>`
  --padding-horizontal: ${(props) => (props.variant === "text" ? rem(5) : rem(10))};
  --padding-vertical: ${(props) => (props.variant === "text" ? 0 : rem(24))};

  font-size: ${(props) => rem(props.theme.fontSize.supporting)};
  line-height: ${(props) => rem(props.theme.lineHeight.supporting)};
  font-weight: ${(props) => props.theme.fontWeight.base};
  font-family: ${(props) => props.theme.font.base};
  letter-spacing: ${rem(1)};
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.dark};

  padding: var(--padding-horizontal) var(--padding-vertical);
  border-radius: ${rem(40)};

  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  vertical-align: middle;

  width: ${(props) => (props.$fullWidth ? "100%" : "auto")};
  outline: none !important;

  transition: transform 0.3s ease, color 0.3s ease;
  will-change: transform;

  &:is(button) {
    cursor: pointer;
  }

  &:hover {
    color: ${(props) => props.theme.colors.dark};
  }

  ${(props) =>
    props.variant === "primary" &&
    css`
      &:hover {
        transform: scale(1.02);
      }
    `}

  ${(props) =>
    props.variant === "text" &&
    css`
      svg {
        transition: transform 0.3s ease, color 0.3s ease;
      }
      &:hover {
        svg {
          transform: translate(3px, 0);
        }
      }
    `}

  // bg and text colors
  ${(props) =>
    props.variant === "primary" &&
    props.color === "violet" &&
    css`
      background-color: ${props.theme.colors.primary};
    `}

  ${(props) =>
    props.variant === "primary" &&
    props.color === "green" &&
    css`
      background-color: ${props.theme.colors.secondary};
    `}

  ${(props) =>
    props.variant === "text" &&
    props.color === "violet" &&
    css`
      svg path {
        stroke: ${(props) => props.theme.colors.primary};
        fill: none;
      }
      svg path:not(["stroke"]) {
        stroke: none;
        fill: ${(props) => props.theme.colors.primary};
      }
    `}

  ${(props) =>
    props.variant === "text" &&
    props.color === "green" &&
    css`
      svg path {
        stroke: #78bfb6;
        fill: none;
      }
      svg path:not(["stroke"]) {
        stroke: none;
        fill: #78bfb6;
      }
    `}

  ${(props) =>
    props.variant === "text" &&
    props.color === "red" &&
    css`
      svg path {
        stroke: ${(props) => props.theme.colors.error};
        fill: none;
      }
      svg path:not(["stroke"]) {
        stroke: none;
        fill: ${(props) => props.theme.colors.error};
      }
    `}

  ${(props) =>
    props.variant === "text" &&
    props.color === "inverse" &&
    css`
      svg path {
        stroke: ${(props) => props.theme.colors.black};
        fill: none;
      }
      svg path:not(["stroke"]) {
        stroke: none;
        fill: ${(props) => props.theme.colors.black};
      }
    `}

  // icons
  ${(props) =>
    props.$icon &&
    css`
      i:not(:only-child),
      img:not(:only-child) {
        margin-right: ${rem(12)};
      }
    `}

  // layouts
  ${(props) =>
    props.$fullWidth &&
    props.layout === "center" &&
    css`
      justify-content: center;
      position: relative;
      padding-left: ${rem(100)};
      padding-right: ${rem(100)};

      @media all and (min-width: 321px) {
        svg,
        img {
          position: absolute;
          left: var(--padding-vertical);
        }
      }
    `}

  ${(props) =>
    props.layout === "space-between" &&
    css`
      justify-content: space-between;
    `}

  ${(props) =>
    props.$textFirst &&
    props.$icon &&
    css`
      flex-direction: row-reverse;
    `}

  @media all and (max-width: 767px) {
    --padding-horizontal: ${(props) => (props.variant === "text" ? "1px" : "6px")};
    --padding-vertical: ${(props) => (props.variant === "text" ? 0 : "12px")};

    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1px;
    padding: var(--padding-horizontal) var(--padding-vertical);
  }
`
