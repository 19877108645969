import styled from "styled-components"
import { rem } from "polished"

export const Section = styled.section`
  padding: ${rem(58)} 0;
  overflow: hidden;
  width: 100%;
  position: relative;

  @media all and (max-width: 767px) {
    padding: 18px 0;
  }
`
