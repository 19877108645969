/* eslint-disable multiline-ternary */
import React from "react"
import { useMediaQuery } from "react-responsive"

import Section from "../../components/Section"
import Button from "../../components/Button"
import Icon from "../../components/Icon"
import Card from "../../components/Card"
import SwipeableStepper from "../../components/SwipeableStepper"

import * as S from "./styled"

import { useInView } from "react-intersection-observer"
import { FadeIn } from "../../components/Animate"

const tableRows = [
  {
    title: "token",
    col1: "Tree Coin",
    col2: "MANGROVE Token",
  },
  {
    title: "token standart",
    col1: "Colored Coin",
    col2: "ERC-20 token",
  },
  {
    title: "blockchain",
    col1: "Bitcoin",
    col2: "Polygon",
  },
  {
    title: "symbol",
    col1: "Tree",
    col2: "mangrove",
  },
  {
    title: "Representation",
    col1: "Planted mangrove in Thor Heyerdahl park and carbon rights",
    col2: "Planted mangrove in Thor Heyerdahl park and governance of the Mangrove DAO, including treasury multisig",
  },
  {
    title: "issued tokens",
    col1: "1’622’750, including 561’027 technical colored coins issued for the offchain settlement",
    col2: "1’061’723",
  },
  {
    title: "Contract",
    col1: (
      <Button
        text="Lykke blockchain explorer"
        variant="text"
        color="green"
        icon={<Icon iconKey="arrowRight" />}
        href="https://blockchainexplorer.lykke.com/asset/AMXVqrRXEwfUuqUE4XozMb6ugb6L6PSFJ8"
      />
    ),
    col2: (
      <Button
        text="POLYGONSCAN"
        variant="text"
        color="violet"
        icon={<Icon iconKey="arrowRight" />}
        href="https://polygonscan.com/token/0xcedf1b0bd0f2a6c1760c419be837ed9f5442e9e4"
      />
    ),
    hideTitle: true,
  },
  {
    title: "Exchange",
    col1: "Lykke",
    col2: "SushiSwap",
  },
  {
    title: "Carbon Tokenization",
    col1: "—",
    col2: "Toucan",
  },
  {
    title: "Issued",
    col1: "2017 (till 2021)",
    col2: "1 Dec 2021",
  },
]

const SectionSpecification: React.FC = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const { ref, inView } = useInView({ triggerOnce: true })

  return (
    <Section as={S.Wrapper} id="specification">
      <S.Title>Specification</S.Title>

      {isMobile ? (
        <SwipeableStepper>
          <Card color="green">
            <S.Table>
              <thead>
                <tr>
                  <S.Col1 as="th">
                    <S.Back />
                    <h4>Heyerdahl Climate Pioneers</h4>
                  </S.Col1>
                </tr>
              </thead>
              <tbody>
                {tableRows.map((item, i) => (
                  <tr key={i}>
                    <S.Col1>
                      <S.Back />
                      <S.Content>
                        {!item.hideTitle && `${item.title}:`} {item.col1}
                      </S.Content>
                    </S.Col1>
                  </tr>
                ))}
              </tbody>
            </S.Table>
          </Card>
          <Card color="violet">
            <S.Table>
              <thead>
                <tr>
                  <S.Col2 as="th">
                    <S.Back />
                    <h4>Mangrove DAO</h4>
                  </S.Col2>
                </tr>
              </thead>
              <tbody>
                {tableRows.map((item, i) => (
                  <tr key={i}>
                    <S.Col2>
                      <S.Back />
                      <S.Content>
                        {!item.hideTitle && `${item.title}:`} {item.col2}
                      </S.Content>
                    </S.Col2>
                  </tr>
                ))}
              </tbody>
            </S.Table>
          </Card>
        </SwipeableStepper>
      ) : (
        <S.Grid ref={ref}>
          <S.Table>
            <thead>
              <tr>
                <th />
                <S.BlankCol as="th" />
                <S.Col1 as="th">
                  <S.Back />
                  <h4>Heyerdahl Climate Pioneers</h4>
                </S.Col1>
                <S.BlankCol as="th" />
                <S.Col2 as="th">
                  <S.Back />
                  <h4>Mangrove DAO</h4>
                </S.Col2>
              </tr>
            </thead>
            <tbody>
              {tableRows.map((item, i) => (
                <tr key={i}>
                  <S.TitleCol>
                    <FadeIn $animate={inView} $delay={i}>
                      <S.Content>{item.title}</S.Content>
                    </FadeIn>
                    <S.Back className="connector" />
                  </S.TitleCol>
                  <S.BlankCol />
                  <S.Col1>
                    <FadeIn $animate={inView} $delay={i}>
                      <S.Content>{item.col1}</S.Content>
                    </FadeIn>
                    <S.Back className="connector" />
                  </S.Col1>
                  <S.BlankCol />
                  <S.Col2>
                    <FadeIn $animate={inView} $delay={i}>
                      <S.Content>{item.col2}</S.Content>
                    </FadeIn>
                    <S.Back className="connector" />
                  </S.Col2>
                </tr>
              ))}
            </tbody>
          </S.Table>
        </S.Grid>
      )}
    </Section>
  )
}

export default SectionSpecification
