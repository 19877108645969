import React from "react"

const ArrowRight = () => {
  return (
    <svg viewBox="0 0 24 24" width="24">
      <path d="M2 12L21 12" strokeWidth="1.5" />
      <path d="M14 20L22 12L14 4" strokeWidth="1.5" />{" "}
    </svg>
  )
}

export default ArrowRight
