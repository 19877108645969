import React, { memo } from "react"
import Slider from "react-slick"
import styled from "styled-components"

interface SliderProps {
  children?: JSX.Element | JSX.Element[] | string | number
  data?: any
}

export const SliderBox = styled.section`
  .slick-list {
    overflow: visible;
    margin-right: 0;
    margin-bottom: 30px;
  }
  .slick-slide {
    padding-right: 20px;
  }
  .slick-dots {
    .slick-active button:before {
      width: 20px;
      opacity: 1;
    }

    li {
      margin: 0;

      button:before {
        content: "";
        width: 4px;
        height: 4px;
        left: 50%;
        top: 50%;
        border-radius: 10px;
        opacity: 0.16;
        background-color: ${(props) => props.theme.colors.black};
        transform: translate(-50%, -50%);
        transition: all 0.3s ease;
      }
    }
  }
`

const CardSliderComponent: React.FC<SliderProps> = ({ children, ...props }) => {
  const settings = {
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    draggable: false,
    dots: true,
    arrows: false,
  }

  return (
    <SliderBox {...props}>
      <Slider {...settings}>{children}</Slider>
    </SliderBox>
  )
}

export default memo(CardSliderComponent)
