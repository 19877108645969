import React from "react"
import { useInView } from "react-intersection-observer"
import { Row, Col } from "react-styled-flexboxgrid"

import { Counter } from "../../components/Counter"
import { Supporting } from "../../components/Typography"

import * as S from "./styled"

export const CounterBlock: React.FC = () => {
  const [ref, inView] = useInView({ triggerOnce: true })

  return (
    <S.RowInner as={Row} center="xs" ref={ref}>
      <S.Col as={Col}>
        <S.Accent color="green">
          <Counter end={3680126} inView={inView} duration={1} />
        </S.Accent>
        <Supporting>Total estimated</Supporting>
      </S.Col>
      <S.Col as={Col}>
        <S.Accent color="red">
          <Counter end={165865} inView={inView} duration={2} />
        </S.Accent>
        <Supporting>Total issued</Supporting>
      </S.Col>
    </S.RowInner>
  )
}
