import React from "react"
import { ThemeProvider } from "styled-components"

import { GlobalStyles } from "./GlobalStyles"
import { FontsHelmet } from "./GlobalFont"
import "./global.css"

import theme from "../config/styled/theme"

import Header from "../containers/Header"
import Footer from "../containers/Footer"

interface Props {
  children: JSX.Element | JSX.Element[]
}

const Layout: React.FC<Props> = ({ children }: Props) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <FontsHelmet />
        <GlobalStyles />

        <Header />
        {children}
        <Footer />
      </ThemeProvider>
    </>
  )
}

export default Layout
