const Fail = () => {
  return (
    <svg viewBox="0 0 32 32">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.4322 21.6472L7.97157 8.48862C7.68961 8.27615 7.65031 7.85404 7.90444 7.60864C9.96195 5.61839 12.7676 4.35822 15.9493 4.33162C21.1393 4.28666 25.8417 7.83018 27.2302 12.8314C28.0818 15.8983 27.6438 18.9426 26.2966 21.4692C26.1308 21.7811 25.7142 21.8597 25.4322 21.6472ZM4.76967 19.1675C3.91809 16.1006 4.35609 13.0563 5.70327 10.5297C5.86912 10.2178 6.2857 10.1392 6.56766 10.3517L24.0283 23.5102C24.3103 23.7227 24.3496 24.1448 24.0954 24.3902C22.0379 26.3805 19.2322 27.6406 16.0506 27.6672C10.8606 27.7122 6.15816 24.1687 4.76967 19.1675ZM14.0518 2.13739C6.40768 3.2117 1.06294 10.303 2.1374 17.9483C3.21171 25.5923 10.3042 30.9369 17.9483 29.8626C25.5924 28.7883 30.9369 21.6958 29.8626 14.0517C28.7882 6.40651 21.6958 1.06309 14.0518 2.13739Z"
      />
    </svg>
  )
}

export default Fail
