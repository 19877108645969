import { createGlobalStyle } from "styled-components"
import { normalize, rem, rgba } from "polished"
import { Grid, Row, Col } from "react-styled-flexboxgrid"

export const GlobalStyles = createGlobalStyle`
  ${normalize()};

  html {
    @media all and (min-width: 992px) and (max-width: 1180px) {
      font-size: 14px;
    }

    @media all and (min-width: 768px) and (max-width: 991px) {
      font-size: 11px;
    }
  }

  body {
    position: relative;
    min-height: 100vh;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-size: ${(props) => rem(props.theme.fontSize.base)};
    font-weight: ${(props) => props.theme.fontWeight.light};
    line-height: ${(props) => rem(props.theme.lineHeight.base)};
    font-family: ${(props) => props.theme.font.base};
    color: ${(props) => props.theme.colors.dark};
    background-color: ${(props) => props.theme.colors.background};
    direction: ltr;
    overscroll-behavior: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    @media all and (max-width: 767px) {
      font-size: 14px;
      line-height: 20px;

      ${Grid} {
        padding-left: 20px;
        padding-right: 20px;
      }

      ${Row} {
        margin-left: -4px;
        margin-right: -4px;
      }

      ${Col} {
        padding-left: 4px;
        padding-right: 4px;
      }
    }
  }

  h1, h2, h3, h4, h5 {
    margin-top: 0;
    font-family: ${(props) => props.theme.font.headings};
    font-weight: ${(props) => props.theme.fontWeight.base};
  }

  a {
    color: inherit;
    text-decoration: none;
    outline: none !important;
    transition: all ${(props) => props.theme.transition.base};

    &:hover {
      color: ${(props) => props.theme.colors.primaryDark};
    }
  }

  p {
    margin-top: 0;

    a {
      display: inline-block;
      font-weight: ${(props) => props.theme.fontWeight.base};
      border-bottom: 1px solid ${rgba("#2C232E", 0.2)};

      &:hover {
        border-bottom-color: transparent;
      }
    }
  }

  h1 {
    margin-top: ${rem(38)};
    margin-bottom: ${rem(80)};
    font-size: ${(props) => rem(props.theme.fontSize.h1)};
    line-height: ${(props) => rem(props.theme.lineHeight.h1)};
    letter-spacing: ${rem(-1)};

    @media all and (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.9px;
      margin-bottom: 23px;
      margin-top: 0;
    }
  }

  h2 {
    margin-bottom: ${rem(42)};
    letter-spacing: ${rem(-0.5)};
    font-size: ${(props) => rem(props.theme.fontSize.h2)};
    line-height: ${(props) => rem(props.theme.lineHeight.h2)};  

    @media all and (max-width: 767px) {
      font-size: 22px;
      line-height: 32px;
      letter-spacing: -0.25px;
      margin-bottom: 13px;
    }
  }

  h3 {
    margin-bottom: ${rem(28)};
    letter-spacing: ${rem(-0.5)};
    font-size: ${(props) => rem(props.theme.fontSize.h3)};
    line-height: ${(props) => rem(props.theme.lineHeight.h3)}; 

    @media all and (max-width: 767px) {
      font-size: 20px;
      line-height: 32px;
      letter-spacing: -0.3px;
      margin-bottom: 21px;
    }
  }

  h4 {
    font-size: ${(props) => rem(props.theme.fontSize.h4)};
    line-height: ${(props) => rem(props.theme.lineHeight.h4)};  

    @media all and (max-width: 767px) {
      font-size: 13px;
      line-height: 16px;
    }
  }

  h5 {
    font-size: ${(props) => rem(props.theme.fontSize.h5)};
    line-height: ${(props) => rem(props.theme.lineHeight.h5)};  

    @media all and (max-width: 767px) {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.25px;
      margin-bottom: 17px;
    }
  }

  button {
    background-color: transparent;
    border: none;
    outline: none !important;
  }

  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }
`
