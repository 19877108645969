import React from "react"
import { withPrefix } from "gatsby"
import { Row, Col } from "react-styled-flexboxgrid"

import Section from "../../components/Section"
import Card from "../../components/Card"
import { Lead, Supporting } from "../../components/Typography"
import Logo from "../../components/Logo"

import { useInView } from "react-intersection-observer"
import { FadeIn } from "../../components/Animate"

import * as S from "./styled"

const cards = [
  "Distribution of tokenized carbon credits, including airdrops",
  "Carbon Tokens Markets Operations",
  "Participation in the new mangrove restoration projects",
  "Development of the Mangrove DAO",
  "Issuance of new Mangrove tokens to crowdfund new restoration projects",
]

const SectionAbout: React.FC = () => {
  const { ref, inView } = useInView({ triggerOnce: true })
  const [refContent, inViewContent] = useInView({ triggerOnce: true })

  return (
    <Section as={S.Wrapper} id="about">
      <Card align="center" size="xs">
        <S.Group ref={refContent}>
          <FadeIn $animate={inViewContent} $delay={0}>
            <S.Logo>
              <Logo size={64} type="accent" />
            </S.Logo>
          </FadeIn>
          <FadeIn $animate={inViewContent} $delay={1}>
            <S.Title>
              MANGROVE token is the new digital representation of 1 planted mangrove in the TREE coin project and the
              governance token of the Mangrove DAO. It is issued on Polygon blockchain.{" "}
            </S.Title>
          </FadeIn>
          <FadeIn $animate={inViewContent} $delay={2}>
            <Lead>Mangrove DAO members can vote on:</Lead>
          </FadeIn>
        </S.Group>

        <S.Row as={Row} ref={ref}>
          {cards.map((item, i) => (
            <S.Col xs={6} sm={4} as={Col} key={i}>
              <FadeIn $animate={inView} $delay={i}>
                <Card size="sm" color="violet" align="center">
                  <S.Image src={withPrefix("/images/point.svg")} alt="*" />
                  <Supporting>{item}</Supporting>
                </Card>
              </FadeIn>
            </S.Col>
          ))}
        </S.Row>

        <S.Group style={{ textAlign: "left" }} ref={ref}>
          <FadeIn $animate={inView} $delay={2}>
            <p>
              Verified carbon credits will be tokenized by WIF or by an appointed agent on behalf of WIF using{" "}
              <a href="https://toucan.earth" target="_blank" rel="noopener noreferrer">
                Toucan Bridge
              </a>
              , as fractionalized NFT tokens &nbsp;
              <a
                href="https://polygonscan.com/token/0xfbc5d9620b6bb729be2f72fca157054bd3da3d1a?a=0xF737f8B819869c3216C8ea33CdA38D8ED829e9F1"
                target="_blank"
                rel="noopener noreferrer"
              >
                (TCO2-VCS-1764-2020)
              </a>
              .
            </p>
            <p>
              Blue Carbon Reference Token pool will be launched to represent 1 generic VCU of Blue Carbon. The liquidity
              pool for Blue Carbon token will then be listed and traded on Sushiswap.
            </p>
          </FadeIn>
        </S.Group>
      </Card>
    </Section>
  )
}

export default SectionAbout
