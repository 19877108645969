import React from "react"

const Menu = () => (
  <svg viewBox="0 0 28 28">
    <circle cx="14" cy="14" r="13.5" fill="white" stroke="#2C232E" />
    <path d="M19 14H9" stroke="black" />
    <path d="M19 10H9" stroke="black" />
    <path d="M19 18H9" stroke="black" />
  </svg>
)

export default Menu
