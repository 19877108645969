import React from "react"

const Medium = () => {
  return (
    <svg viewBox="0 0 32 32" width="32">
      <path d="M17.5321 15.7581C17.5321 19.9737 14.1379 23.391 9.95121 23.391C5.76453 23.391 2.37012 19.9737 2.37012 15.7581C2.37012 11.5426 5.76428 8.125 9.95121 8.125C14.1381 8.125 17.5321 11.5426 17.5321 15.7581Z" />
      <path d="M25.8484 15.7583C25.8484 19.7263 24.1514 22.9443 22.0579 22.9443C19.9644 22.9443 18.2673 19.7263 18.2673 15.7583C18.2673 11.7903 19.9642 8.57227 22.0576 8.57227C24.1511 8.57227 25.8484 11.7892 25.8484 15.7583Z" />
      <path d="M27.9169 22.1958C28.6532 22.1958 29.25 19.3127 29.25 15.7583C29.25 12.2029 28.653 9.3208 27.9169 9.3208C27.1808 9.3208 26.584 12.2032 26.584 15.7583C26.584 19.3135 27.1805 22.1958 27.9169 22.1958Z" />
    </svg>
  )
}

export default Medium
