import styled from "styled-components"
import { rem } from "polished"

export const Wrapper = styled.section`
  padding-top: ${rem(88)};

  @media all and (max-width: 767px) {
    padding-top: 60px !important;
  }
`

export const Group = styled.section`
  max-width: ${rem(870)};
  margin-left: auto;
  margin-right: auto;
`

export const Title = styled.h1``

export const Image = styled.img`
  display: inline-block;
  vertical-align: middle;
  width: ${rem(72)};
  position: relative;
  top: ${rem(-8)};
  margin: ${rem(-20)} 0;

  @media all and (max-width: 767px) {
    width: 36px;
    margin: 0;
    top: -2px;

    svg {
      height: auto;
      width: 100%;
    }

    > div {
      width: 100%;
    }
  }
`
