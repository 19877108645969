import React from "react"

import Section from "../../components/Section"
import Card from "../../components/Card"
import { Row, Col } from "react-styled-flexboxgrid"

import Toucan from "../../assets/images/partners-toucan.svg"
import Vlinder from "../../assets/images/partners-vlinder.svg"
import Polygon from "../../assets/images/partners-polygon.svg"

import { useInView } from "react-intersection-observer"
import { FadeIn } from "../../components/Animate"

import * as S from "./styled"

const SectionPartners: React.FC = () => {
  const { ref, inView } = useInView({ triggerOnce: true })

  return (
    <Section as={S.Wrapper} id="partners">
      <Card color="gray" size="md">
        <S.Title>Powered by</S.Title>

        <Row center="xs" bottom="xs" ref={ref}>
          <S.Col as={Col}>
            <FadeIn $animate={inView} $delay={0}>
              <a href="https://toucan.earth" target="_blank" rel="noopener noreferrer">
                <Toucan as={S.Image} width="240"></Toucan>
              </a>
            </FadeIn>
          </S.Col>
          <S.Col as={Col}>
            <FadeIn $animate={inView} $delay={1}>
              <a href="https://vlinderclimate.com">
                <Vlinder as={S.Image} width="240"></Vlinder>
              </a>
            </FadeIn>
          </S.Col>
          <S.Col as={Col}>
            <FadeIn $animate={inView} $delay={2}>
              <a href="https://polygon.technology">
                <Polygon as={S.Image} width="240"></Polygon>
              </a>
            </FadeIn>
          </S.Col>
        </Row>
      </Card>
    </Section>
  )
}

export default SectionPartners
