const Facebook = () => {
  return (
    <svg viewBox='0 0 16 16'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 0C12.4183 0 16 3.58172 16 8C16 11.993 13.0745 15.3027 9.25 15.9028V10.3125H11.1141L11.4688 8H9.25V6.5C9.25 5.9063 9.52246 5.32716 10.3753 5.25708C10.4322 5.25241 10.4916 5.25 10.5538 5.25H11.5625V3.28125C11.5625 3.28125 10.9579 3.1781 10.2619 3.13942C10.1013 3.13049 9.93578 3.125 9.77172 3.125C7.94438 3.125 6.75 4.2325 6.75 6.2375V8H4.71875V10.3125H6.75V15.9028C2.92547 15.3027 0 11.993 0 8C0 3.58172 3.58172 0 8 0Z'
      />
    </svg>
  )
}

export default Facebook
