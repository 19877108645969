import styled from "styled-components"
import { rem } from "polished"

export const Footer = styled.footer`
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 0 0 ${rem(40)} 0;

  @media all and (max-width: 767px) {
    padding: 16px;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
  }
`

export const Banner = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
`

export const Social = styled.div`
  text-align: right;

  a {
    padding: ${rem(8)};

    .icon path {
      transition: fill 0.3s ease;
    }

    &:hover .icon path {
      fill: ${(props) => props.theme.colors.primaryDark};
    }
  }

  @media all and (max-width: 767px) {
    margin-top: 12px;
    text-align: center;

    a {
      padding: 4px;
    }

    .icon {
      height: 24px;
      width: 24px;
    }
  }
`
