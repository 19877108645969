import styled, { css } from "styled-components"
import { rem } from "polished"

export type Size = "xs" | "sm" | "md" | "lg"
export type Color = "green" | "violet" | "gray" | "transparent"
export type Align = "left" | "center" | "right"

interface CardProps {
  $size: Size
  $color: Color
  $align: Align
}

export const Card = styled.div<CardProps>`
  overflow: hidden;
  width: 100%;
  position: relative;
  border-radius: ${rem(20)};
  margin-bottom: ${rem(10)};
  background-color: ${(props) => props.theme.colors.white};

  // bg colors
  ${(props) =>
    props.$color === "violet" &&
    css`
      background-color: ${props.theme.colors.primaryLight};
    `}
  ${(props) =>
    props.$color === "green" &&
    css`
      background-color: ${props.theme.colors.secondary};
    `}
  ${(props) =>
    props.$color === "gray" &&
    css`
      background-color: ${props.theme.colors.gray};
    `}

  // sizes
  ${(props) =>
    props.$size === "xs" &&
    css`
      padding: 0 !important;
      margin-bottom: 0;
    `}
  ${(props) =>
    props.$size === "sm" &&
    css`
      padding: ${rem(36)} ${rem(32)};
    `}
  ${(props) =>
    props.$size === "md" &&
    css`
      padding: ${rem(64)} ${rem(80)};
    `}
  ${(props) =>
    props.$size === "lg" &&
    css`
      padding: ${rem(84)} ${rem(100)} ${rem(75)};
      margin-bottom: 0;
    `}

  // align
  ${(props) =>
    props.$align &&
    css`
      text-align: ${props.$align};
    `}

  @media all and (max-width: 767px) {
    padding: 30px 20px;
    border-radius: 12px;
    margin-bottom: 8px;
  }
`
