import React from "react"

const Discord = () => {
  return (
    <svg viewBox="0 0 32 32" width="32">
      <path d="M24.3634 5.00342H8.43669C7.09354 5.00342 6 6.10563 6 7.47147V23.6695C6 25.0353 7.09346 26.1376 8.43661 26.1376H21.915L21.285 23.9211L22.8064 25.3468L24.2445 26.6887L26.8 28.965V7.47147C26.8 6.10563 25.7065 5.00342 24.3634 5.00342ZM19.7755 20.6504C19.7755 20.6504 19.3477 20.1352 18.9911 19.6799C20.5481 19.2366 21.1424 18.2542 21.1424 18.2542C20.6551 18.5777 20.1915 18.8054 19.7755 18.961C19.1813 19.2127 18.6107 19.3803 18.0521 19.4762C16.9111 19.6919 15.8651 19.632 14.9737 19.4643C14.2962 19.3324 13.7138 19.1407 13.2265 18.9491C12.9531 18.8412 12.656 18.7095 12.3588 18.5417C12.3232 18.5177 12.2875 18.5058 12.2519 18.4818C12.2281 18.4698 12.2162 18.4579 12.2043 18.4459C11.9904 18.3261 11.8715 18.2422 11.8715 18.2422C11.8715 18.2422 12.4421 19.2007 13.9515 19.6559C13.595 20.1113 13.1552 20.6503 13.1552 20.6503C10.5284 20.5665 9.53007 18.8293 9.53007 18.8293C9.53007 14.9715 11.2416 11.8444 11.2416 11.8444C12.9531 10.5505 14.5815 10.5865 14.5815 10.5865L14.7003 10.7303C12.5609 11.3531 11.5744 12.2997 11.5744 12.2997C11.5744 12.2997 11.8359 12.156 12.2756 11.9523C13.5474 11.3892 14.5577 11.2335 14.9737 11.1975C15.045 11.1855 15.1045 11.1735 15.1758 11.1735C15.9723 11.0689 16.7784 11.0609 17.5767 11.1496C18.7058 11.2814 19.9182 11.6168 21.1543 12.2997C21.1543 12.2997 20.2153 11.4012 18.1947 10.7782L18.3611 10.5865C18.3611 10.5865 19.9895 10.5505 21.701 11.8445C21.701 11.8445 23.4126 14.9715 23.4126 18.8293C23.4126 18.8293 22.4023 20.5665 19.7755 20.6503V20.6504ZM14.2487 15.0553C13.5712 15.0553 13.0364 15.6543 13.0364 16.3851C13.0364 17.116 13.5831 17.715 14.2487 17.715C14.9262 17.715 15.461 17.116 15.461 16.3851C15.4729 15.6543 14.9262 15.0553 14.2487 15.0553H14.2487ZM18.587 15.0553C17.9095 15.0553 17.3746 15.6543 17.3746 16.3851C17.3746 17.116 17.9214 17.715 18.587 17.715C19.2645 17.715 19.7993 17.116 19.7993 16.3851C19.7993 15.6543 19.2645 15.0553 18.587 15.0553Z" />
    </svg>
  )
}

export default Discord
