/* eslint-disable multiline-ternary */
import React from "react"
import ReactTooltip from "react-tooltip"

import Section from "../../components/Section"
import Card from "../../components/Card"
import Button from "../../components/Button"
import Icon from "../../components/Icon"
import { Supporting } from "../../components/Typography"

import * as S from "./styled"

import { useInView } from "react-intersection-observer"
import { FadeIn } from "../../components/Animate"

const details = [
  {
    title: "Web-sites",
    linkText: "mangrovedao.earth",
    linkUrl: "https://mangrovedao.earth",
  },
  {
    title: "Ethereum ENS",
    linkText: "mangrovedao.eth",
    linkUrl: "https://app.ens.domains/name/mangrovedao.eth",
  },
  {
    title: "DAO communications",
    linkText: "Discord",
    linkUrl: "https://discord.gg/mnVEmzzjeb",
  },
  {
    title: "DAO VOTING",
    linkText: "Snapshot",
    linkUrl: "https://snapshot.org/#/mangrovedao.eth/",
  },
  {
    title: "TCO2 Tokenization",
    linkText: "Toucan Bridge",
    linkUrl: "https://toucan.earth",
  },
  {
    title: "Social Media",
    linkText: "Medium",
    linkUrl: "https://medium.com/@mangrovedao",
  },
  {
    title: "DAO Treasury",
    linkText: "GnosisSafe multisig",
  },
  {
    title: "Liquidity pool",
    linkText: "MANGROVE/USDC",
    linkUrl: "https://medium.com/@mangrovedao/how-to-trade-mangrove-dao-tokens-on-sushiswap-9c23bf04aba4",
  },
  {
    title: "Reference Token",
    linkText: "MRT",
    tooltip: "Mangrove Removal Tonne will be deployed on Toucan Bridge Protocol",
  },
]

const SectionDetails: React.FC = () => {
  const { ref, inView } = useInView({ triggerOnce: true })

  return (
    <Section as={S.Wrapper} id="details">
      <Card color="gray" size="md">
        <S.Title>Details</S.Title>

        <S.Grid ref={ref}>
          {details.map((item, i) => (
            <S.Col key={i}>
              <FadeIn $animate={inView} $delay={i}>
                <Supporting>{item.title}</Supporting>

                <S.Button>
                  {item.linkUrl ? (
                    <Button
                      text={item.linkText}
                      href={item.linkUrl}
                      variant="text"
                      color="red"
                      icon={<Icon iconKey="arrowRight" />}
                    />
                  ) : (
                    <S.Text>{item.linkText}</S.Text>
                  )}

                  {item.tooltip && (
                    <S.Tooltip>
                      <a data-tip data-for={`tooltip-id-${i}`}>
                        <Icon iconKey="infoCircle" />
                      </a>

                      <ReactTooltip place="top" id={`tooltip-id-${i}`} type="dark" effect="solid">
                        <S.TooltipText>{item.tooltip}</S.TooltipText>
                      </ReactTooltip>
                    </S.Tooltip>
                  )}
                </S.Button>
              </FadeIn>
            </S.Col>
          ))}
        </S.Grid>
      </Card>
    </Section>
  )
}

export default SectionDetails
