import React from "react"
import Helmet from "react-helmet"
import { withPrefix } from "gatsby"

export const GlobalFontFace = `
  @font-face {
    font-family: "HK Grotesk";
    src: url(${withPrefix("/fonts/HKGrotesk-Light.woff2")}) format("woff2"),
    url(${withPrefix("/fonts/HKGrotesk-Light.woff")}) format("woff");
    font-weight:300;
    font-display:swap;
  }

  @font-face {
    font-family: "HK Grotesk";
    src: url(${withPrefix("/fonts/HKGrotesk-Regular.woff2")}) format("woff2"),
    url(${withPrefix("/fonts/HKGrotesk-Regular.woff")}) format("woff");
    font-weight:400;
    font-display:swap;
  }
`

export const FontsHelmet = () => {
  const preLoadAttrs = { rel: "preload", as: "style" }
  const stylesheetAttrs = {
    rel: "stylesheet",
    media: "print",
    onload: "media='all'",
  }
  const googleFontsAPI = `https://fonts.googleapis.com/css2?`
  const universalFontsAPI = `${googleFontsAPI}family=Playfair+Display&display=swap`

  return (
    <Helmet>
      <title>Mangrove DAO</title>
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Mangrove DAO" />
      <meta property="og:image" content={withPrefix("/images/preview-1.png")} />
      <meta
        property="og:description"
        content="Mangrove DAO is a Decentralized Autonomous Organization. It implements novel governance mechanisms to empower the community of TREE coin holders."
      />
      <meta
        name="description"
        content="Mangrove DAO is a Decentralized Autonomous Organization. It implements novel governance mechanisms to empower the community of TREE coin holders."
      />

      <style>{GlobalFontFace}</style>

      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />

      <link href={universalFontsAPI} {...preLoadAttrs} />
      <link href={universalFontsAPI} {...stylesheetAttrs} />

      {/* Meta */}
      <link rel="shortcut icon" href={withPrefix("/images/apple-touch-icon.png")} />
      <link rel="apple-touch-icon" sizes="180x180" href={withPrefix("/images/apple-touch-icon.png")} />
      <link rel="icon" type="image/png" sizes="32x32" href={withPrefix("/images/favicon-32x32.png")} />
      <link rel="icon" type="image/png" sizes="16x16" href={withPrefix("/images/favicon-16x16.png")} />
      <link rel="manifest" href={withPrefix("/images/site.webmanifest")} />
      <meta name="theme-color" content="#ffffff" />
      <meta
        name="viewport"
        content="width=device-width, height=device-height, initial-scale=1.0, user-scalable=0, minimum-scale=1.0, maximum-scale=1.0"
      />
    </Helmet>
  )
}
