import React from "react"

import Section from "../../components/Section"
import { Row, Col } from "react-styled-flexboxgrid"

import { Lead } from "../../components/Typography"

import { useInView } from "react-intersection-observer"
import { FadeIn } from "../../components/Animate"

import * as S from "./styled"

const data = [
  {
    title: "2017",
    list: [
      <>
        TREE coins issued and listed on{" "}
        <a href="https://lykke.com/" target="_blank" rel="noopener noreferrer">
          Lykke Exchange
        </a>
      </>,
    ],
  },
  {
    title: "2018",
    list: [
      <>
        WIF mangrove restoration project registered by{" "}
        <a href="https://registry.verra.org/app/projectDetail/VCS/1764" target="_blank" rel="noopener noreferrer">
          Verra
        </a>
      </>,
    ],
  },
  {
    title: "2021",
    list: [
      "December 2, 2021. Lykke delisting announced.",
      "January 2, 2022. Migration from Lykke ended. The later requests will be considered on an ad hoc basis.",
    ],
  },
  {
    title: "2022",
    list: [
      "GnosisSafe Multisig created",
      "Interim treasury holders elected",
      "First Blue Carbon credits tokenized and sent to the Treasury",
      "Mangrove Removal Tonne on Toucan Protocol",
      "Treasury distribution proposals DAO voting",
    ],
  },
  {
    title: "2023",
    list: [
      "Migration of the Treasury to SafeSnap or similar mechanism allowing direct distribution of funds based on DAO voting",
      "Mangrove DAO development",
    ],
  },
]

const SectionRoadmap: React.FC = () => {
  const { ref, inView } = useInView({ triggerOnce: true })

  return (
    <Section as={S.Wrapper} id="roadmap">
      <S.Title>Roadmap</S.Title>

      {data.map((item, i) => (
        <S.Group key={i} ref={ref}>
          <Row>
            <S.Col as={Col} xs>
              <FadeIn $animate={inView} $delay={i}>
                <h4>{item.title}</h4>
              </FadeIn>
            </S.Col>
            <S.Col as={Col} xs={10}>
              <FadeIn $animate={inView} $delay={i}>
                <S.List>
                  {item.list.map((text, j) => (
                    <Lead as="li" key={j}>
                      {text}
                    </Lead>
                  ))}
                </S.List>
              </FadeIn>
            </S.Col>
          </Row>
        </S.Group>
      ))}
    </Section>
  )
}

export default SectionRoadmap
