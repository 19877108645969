export const CURRENT_YEAR = new Date().getFullYear()

export const FIELD_MAX_LENGTH = {
    name: 60,
    email: 256,
    description: 1000,
    address: 45,
    tezosAddress: 36,
  }

  export const FIELD_PATTERNS = {
    email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  }

  export const FIELD_MIN_LENGTH = {
    name: 3,
    password: 8,
  }

  export const SOCIAL_LINKS = [
    {
    icon: "discord",
    url: "https://discord.gg/mnVEmzzjeb",
    name: "",
    medium: "Discord",
    },
    {
    icon: "medium",
    url: "https://medium.com/@mangrovedao",
    name: "",
    medium: "Medium",
    },
  ]

  export const VALID_EMAIL_PATTERN = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
