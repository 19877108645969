import styled, { css } from "styled-components"
import { rem } from "polished"

export const Card = styled.div`
  overflow: visible;
`

export const Wrapper = styled.section`
  ${Card} ${Card} {
    background: rgba(255, 255, 255, 0.8);
  }
`

export const Title = styled.h2`
  margin-bottom: ${rem(40)};
  margin-top: ${rem(30)};
  text-align: center;

  @media all and (max-width: 767px) {
    margin-bottom: 25px;
    margin-top: 0;
  }
`

export const Image = styled.img`
  width: ${rem(582)};
  display: block;
  border-radius: ${rem(40)};
  margin-top: ${rem(25)};

  @media all and (max-width: 767px) {
    margin-top: 0;
  }
`

export const Logo = styled.div`
  margin: 0 auto ${rem(30)};
  display: flex;
  justify-content: center;
`

export const Col = styled.div`
  padding: 0 ${rem(40)};
`

export const Row = styled.div`
  margin: 0 ${rem(-40)};
  margin-bottom: 20px;

  @media all and (min-width: 992px) {
    margin-bottom: ${rem(80)};
  }
`

export const RowInner = styled.div`
  margin: 0 ${rem(-40)};
  margin-bottom: 10px;

  @media all and (max-width: 767px) {
    margin-bottom: 0;

    > div {
      width: 100%;
      margin-bottom: 13px;
    }
  }
`

export const Button = styled.div`
  display: flex;
  margin-top: ${rem(28)};
  justify-content: center;

  @media all and (max-width: 767px) {
    margin-bottom: 18px;
  }
`

export const Accent = styled.h3`
  font-size: ${(props) => rem(props.theme.fontSize.h2)};
  line-height: ${(props) => rem(props.theme.lineHeight.h2)};
  color: ${(props) => props.theme.colors.secondaryDark};
  letter-spacing: -1.25px;
  font-feature-settings: "pnum" on, "lnum" on, "tnum";
  margin-bottom: ${rem(7)};
  width: 189px;
  max-width: 100%;

  ${(props) =>
    props.color === "red" &&
    css`
      color: ${props.theme.colors.error};
    `}

  @media all and (max-width: 767px) {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -1.25px;
    margin-bottom: ${rem(3)};
    width: 100%;
  }
`

export const Group = styled.div`
  max-width: ${rem(600)};
  margin-top: ${rem(90)};
  margin-bottom: ${rem(30)};
  margin-left: auto;
  margin-right: auto;

  p {
    margin-bottom: 0;
  }

  @media all and (max-width: 767px) {
    margin-top: 22px;
    margin-bottom: 10px;
  }
`

export const Graph = styled.div`
  margin: ${rem(40)} ${rem(-120)} ${rem(-40)};

  .rv-xy-plot__series--mark {
    circle {
      r: 6px;
      cursor: pointer;
      &:not(:hover) {
        fill: none !important;
      }
    }
  }

  .rv-xy-plot__axis--vertical {
    display: none;
  }

  .rv-xy-plot__grid-lines__line,
  .rv-xy-plot__axis__line {
  }

  .rv-xy-plot__axis__tick__line {
    opacity: 0 !important;
  }

  .rv-xy-plot__axis__tick__text {
    font-size: 19px;
    line-height: 25px;
    text-align: center;
    font-feature-settings: "pnum" on, "lnum" on;
    fill: ${(props) => props.theme.colors.dark};
    font-family: ${(props) => props.theme.font.headings};
    opacity: 1;
  }

  .rv-hint--horizontalAlign-right {
    transform: translate(-50%, -20px);
  }

  .rv-hint--horizontalAlign-left {
    transform: translate(50%, -20px);
  }

  .rv-xy-plot__axis__tick {
    &:first-child {
      transform: translate(40px, 0);
    }
    &:last-child {
      transform: translate(calc(100% - 120px), 0);
    }
  }
`

export const Hint = styled.div`
  color: #fff;
  background-color: #2c232e;
  border-radius: ${rem(12)};
  padding: ${rem(16)};
  text-align: center;
  font-family: ${(props) => props.theme.font.headings};
  font-feature-settings: "pnum" on, "lnum" on;
  position: relative;

  h4 {
    font-size: ${rem(19)};
    line-height: ${rem(25)};
    text-align: center;
    margin: 0;
  }

  p {
    font-size: ${rem(12)};
    line-height: ${rem(20)};
    text-align: right;
    margin-bottom: 0;
  }

  &::before,
  &::after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
  }

  &::before {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    bottom: -8px;
    left: 50%;
    margin-left: -10px;
  }

  &:before {
    border-top: 8px solid transparent;
  }

  &::after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    bottom: -6px;
    left: 50%;
    margin-left: -8px;
    border-top-color: #222;
    border-top-style: solid;
    border-top-width: 6px;
  }

  &::after {
    border-top-color: #2c232e;
  }
`
