import React from "react"

const InfoCircle = () => {
  return (
    <svg viewBox="0 0 20 20" width={20}>
      <circle cx="10" cy="10" r="9.25" stroke="#2C232E" strokeWidth="1.5" fill="none" />
      <path
        fill="#2C232E"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 6.23804C11.5 6.57923 11.3828 6.87046 11.1472 7.1099C10.9122 7.35056 10.6289 7.47059 10.2978 7.47059C9.96554 7.47059 9.68165 7.35056 9.44387 7.1099C9.20665 6.87046 9.08776 6.57923 9.08776 6.23804C9.08776 5.89745 9.20665 5.60561 9.44387 5.36312C9.68109 5.12064 9.96554 5 10.2978 5C10.6289 5 10.9122 5.12124 11.1472 5.36312C11.3828 5.60561 11.5 5.89745 11.5 6.23804ZM11.3821 14.3607C11.0285 14.5307 10.7457 14.6599 10.5351 14.749C10.3246 14.8381 10.0796 14.8824 9.80069 14.8824C9.37235 14.8824 9.03909 14.7549 8.80139 14.5012C8.56369 14.2468 8.44484 13.9241 8.44484 13.5329C8.44484 13.3812 8.45357 13.2254 8.47152 13.0667C8.48947 12.908 8.51809 12.7286 8.55738 12.5285L8.99979 10.625C9.03908 10.4426 9.07256 10.2697 9.09924 10.1063C9.1264 9.94402 9.1395 9.79473 9.1395 9.66019C9.1395 9.41708 9.09827 9.24714 9.0158 9.15155C8.93333 9.05596 8.77616 9.00757 8.54283 9.00757C8.42834 9.00757 8.31095 9.02999 8.19113 9.07307C8.07082 9.11615 8 8.60986 8 8.60986C8.28961 8.46647 8.5666 8.34374 8.83146 8.24165C9.09633 8.13957 9.34664 8.08824 9.58337 8.08824C10.0088 8.08824 10.3372 8.21333 10.5676 8.46352C10.7981 8.71371 10.9135 9.03826 10.9135 9.43833C10.9135 9.52094 10.9058 9.66669 10.8897 9.87498C10.8737 10.0839 10.8441 10.2751 10.801 10.4491L10.3605 12.3456C10.3246 12.4979 10.2921 12.6719 10.264 12.8678C10.2349 13.0626 10.2208 13.2113 10.2208 13.311C10.2208 13.5629 10.2669 13.7347 10.3595 13.8261C10.4527 13.9176 10.6132 13.963 10.8412 13.963C10.9484 13.963 11.0702 13.94 11.2055 13.8946C11.3409 13.8491 11.3821 14.3607 11.3821 14.3607Z"
      />
    </svg>
  )
}

export default InfoCircle
