const theme = {
  flexboxgrid: {
    gridSize: 12, // columns
    gutterWidth: 1.25, // rem
    outerMargin: 2.5, // rem
    mediaQuery: "only screen",
    container: {
      sm: 0, // rem
      md: 64, // rem
      lg: 77.5, // rem
    },
    breakpoints: {
      xs: 0, // em
      sm: 48, // em
      md: 64, // em
      lg: 80.3, // em
    },
  },
  font: {
    base: "'HK Grotesk', sans-serif",
    headings: "'Playfair Display', serif",
  },
  fontSize: {
    base: 19,
    supporting: 14,
    lead: 24,
    h1: 64,
    h2: 48,
    h3: 36,
    h4: 28,
    h5: 24,
  },
  lineHeight: {
    base: 32,
    supporting: 24,
    lead: 36,
    h1: 72,
    h2: 56,
    h3: 52,
    h4: 32,
    h5: 32,
  },
  fontWeight: {
    light: 300,
    base: 400,
    medium: 500,
    bold: 700,
  },
  colors: {
    primary: "#C6B4FF",
    primaryLight: "#F3F3FE",
    primaryDark: "#6F4CDC",
    secondary: "#E1F2F0",
    secondaryDark: "#186f64",
    background: "#FFFFFF",
    dark: "#222533",
    accent: "#232d91",
    odd: "#c3f1ff",
    gray: "#F0F0F0",
    black: "#2C232E",
    white: "#ffffff",
    error: "#E85531",
    success: "#3acabf",
    warning: "#ffe053",
    mint: "#98ede3",
  },
  transition: {
    base: "0.3s ease",
  },
}

export default theme
