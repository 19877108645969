const Twitter = () => {
  return (
    <svg viewBox="0 0 32 32">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 8.49247C29.0278 8.92628 27.9962 9.21127 26.9397 9.33791C28.0484 8.66961 28.8778 7.62052 29.2738 6.38536C28.2279 7.00954 27.0832 7.44946 25.8893 7.68604C24.2399 5.92481 21.6178 5.49229 19.493 6.63097C17.3682 7.76965 16.2691 10.1964 16.812 12.5506C12.5316 12.3357 8.54361 10.3073 5.8414 6.97067C4.42947 9.41028 5.15039 12.5305 7.48828 14.0984C6.64306 14.0733 5.81608 13.8459 5.07631 13.4351V13.5001C5.0931 16.028 6.87298 18.1985 9.34264 18.7028C8.56029 18.9158 7.73981 18.9469 6.94364 18.7939C7.64066 20.9661 9.63557 22.4551 11.9102 22.5008C10.0248 23.9876 7.69507 24.794 5.29676 24.79C4.86334 24.7901 4.43031 24.764 4 24.712C8.66025 27.73 14.5933 27.9487 19.4618 25.2819C24.3303 22.6151 27.354 17.4903 27.3416 11.9263C27.3416 11.6921 27.3416 11.4624 27.3416 11.2369C28.3829 10.4873 29.283 9.55805 30 8.49247Z"
      />
    </svg>
  )
}

export default Twitter
