const Check = () => {
  return (
    <svg viewBox="0 0 32 32">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7992 7.73066C24.5003 8.17206 24.7107 9.09819 24.2693 9.79923L15.7693 23.2992C15.5263 23.6853 15.1207 23.9398 14.6673 23.9907C14.2139 24.0415 13.762 23.8833 13.4393 23.5607L7.93934 18.0607C7.35355 17.4749 7.35355 16.5251 7.93934 15.9394C8.52513 15.3536 9.47487 15.3536 10.0607 15.9394L14.2319 20.1106L21.7307 8.20079C22.172 7.49975 23.0982 7.28927 23.7992 7.73066Z"
      />
    </svg>
  )
}

export default Check
