const Close = () => {
  return (
    <svg viewBox="0 0 32 32">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7782 22.7789L17.5556 16.5563L23.7782 10.3338C24.2075 9.90445 24.2075 9.20753 23.7782 8.77817C23.3488 8.34882 22.6519 8.34882 22.2225 8.77817L16 15.0007L9.77746 8.77817C9.34811 8.34882 8.65118 8.34882 8.22183 8.77817C7.79247 9.20753 7.79247 9.90445 8.22183 10.3338L14.4444 16.5563L8.22183 22.7789C7.79247 23.2082 7.79247 23.9052 8.22183 24.3345C8.65118 24.7639 9.34811 24.7639 9.77746 24.3345L16 18.112L22.2225 24.3345C22.6519 24.7639 23.3488 24.7639 23.7782 24.3345C24.2075 23.9052 24.2075 23.2082 23.7782 22.7789"
      />
    </svg>
  )
}

export default Close
