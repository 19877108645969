import styled from "styled-components"
import { rem } from "polished"

import { Card } from "../../components/Card/styled"

export const Wrapper = styled.section``

export const Group = styled.div`
  max-width: ${rem(680)};
  margin-left: auto;
  margin-right: auto;

  p + p {
    margin-bottom: 8px;
  }
`

export const Title = styled.h3`
  margin-bottom: ${rem(119)};
  font-feature-settings: "pnum" on, "lnum" on;

  @media all and (max-width: 767px) {
    margin-bottom: 20px;
  }
`

export const Image = styled.img`
  width: ${rem(40)};
  margin: 0 auto ${rem(27)};
  display: block;
`

export const Logo = styled.div`
  margin: 0 auto ${rem(30)};
  display: flex;
  justify-content: center;

  @media all and (max-width: 767px) {
    margin-bottom: 16px;
  }
`

export const Col = styled.div`
  height: 100%;
  flex-basis: 100%;
  max-width: 100%;
  padding-bottom: 8px;

  > div,
  ${Card} {
    height: 100%;
    margin-bottom: 0;
  }

  @media all and (min-width: 768px) {
  }

  @media all and (min-width: 768px) {
    padding-bottom: 0;

    > div,
    ${Card} {
      margin-bottom: 0;

      > div {
        margin-bottom: 28px;
      }
    }
  }
`

export const Row = styled.div`
  margin-bottom: 30px;
  display: grid;
  margin-top: 20px;

  @media all and (max-width: 767px) {
    grid-template-columns: 50% 50%;
  }

  @media all and (min-width: 768px) {
    margin-bottom: ${rem(70)};
    margin-top: ${rem(45)};
    grid-auto-flow: column;
  }
`
