import styled from "styled-components"
import { rem } from "polished"

export const Wrapper = styled.section``

export const Title = styled.h2`
  margin-bottom: ${rem(70)};

  @media all and (max-width: 767px) {
    margin-bottom: 24px;
  }
`

export const Image = styled.img`
  width: ${rem(40)};
  margin: 0 auto ${rem(27)};
  display: block;
`

export const Logo = styled.div`
  margin: 0 auto ${rem(30)};
  display: flex;
  justify-content: center;
`

export const Col = styled.div`
  margin-bottom: 12px;

  @media all and (min-width: 992px) {
    margin-bottom: ${rem(38)};
  }
`

export const Grid = styled.div`
  @media all and (max-width: 991px) {
    flex-direction: column;
    display: flex;

    > div {
      &:nth-child(1) {
        > div {
          transition-delay: 0.1s;
        }
      }
      &:nth-child(2) {
        > div {
          transition-delay: 0.2s;
        }
      }
      &:nth-child(3) {
        > div {
          transition-delay: 0.3s;
        }
      }
      &:nth-child(4) {
        > div {
          transition-delay: 0.4s;
        }
      }
      &:nth-child(5) {
        > div {
          transition-delay: 0.5s;
        }
      }
      &:nth-child(6) {
        > div {
          transition-delay: 0.6s;
        }
      }
      &:nth-child(7) {
        > div {
          transition-delay: 0.7s;
        }
      }
      &:nth-child(8) {
        > div {
          transition-delay: 0.8s;
        }
      }
      &:nth-child(9) {
        > div {
          transition-delay: 0.9s;
        }
      }
    }
  }

  @media all and (min-width: 992px) {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-auto-flow: column;
    grid-template-columns: repeat(3, 3fr);
  }
`

export const Text = styled.span`
  font-family: ${(props) => props.theme.font.headings};
  font-size: ${(props) => rem(props.theme.fontSize.lead)};
  line-height: 46px;
  text-transform: none;
  letter-spacing: normal;
  display: inline-flex;
  align-items: center;

  .icon,
  span {
    display: block;
  }

  .icon {
    position: relative;
    top: ${rem(2)};
  }

  @media all and (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
  }
`

export const Button = styled.div`
  display: flex;
  align-items: center;

  > a {
    font-family: ${(props) => props.theme.font.headings};
    font-size: ${(props) => rem(props.theme.fontSize.lead)};
    line-height: ${(props) => rem(props.theme.lineHeight.lead)};
    text-transform: none;
    letter-spacing: normal;
    display: inline-flex;
    align-items: center;

    .icon,
    span {
      display: block;
    }

    .icon {
      position: relative;
      top: ${rem(2)};
    }
  }
  @media all and (max-width: 767px) {
    display: flex;
    justify-content: space-between;

    > a {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.25px;
    }
  }
`

export const Tooltip = styled.div`
  cursor: pointer;
  display: inline-block;
  margin-left: ${rem(12)};
  vertical-align: middle;
  position: relative;

  .__react_component_tooltip {
    background-color: ${(props) => props.theme.colors.black};
    border-radius: ${rem(12)};
    padding: ${rem(13)} ${rem(16)};
    max-width: ${rem(192)};
    text-align: center;

    &.show {
      opacity: 1;
    }

    &.place-top {
      margin-top: -2px;
      &::after {
        border-top-color: ${(props) => props.theme.colors.black};
      }
    }
  }

  @media all and (max-width: 767px) {
    .icon {
      height: 16px;
      width: 16px;
    }
  }
`

export const TooltipText = styled.div`
  font-family: ${(props) => props.theme.font.headings};
  font-size: ${rem(12)};
  line-height: ${rem(20)};
  font-feature-settings: "pnum" on, "lnum" on;
  color: ${(props) => props.theme.colors.white};
`
