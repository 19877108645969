import styled from "styled-components"
import { rem } from "polished"

export const Wrapper = styled.section`
  svg path {
    transition: fill 0.3s ease;
  }

  a:hover svg {
    path {
      fill: ${(props) => props.theme.colors.primaryDark};
    }
  }

  @media all and (max-width: 767px) {
    svg {
      width: 80px;
    }
  }
`

export const Title = styled.h2`
  margin-bottom: ${rem(68)};
  text-align: center;

  @media all and (max-width: 767px) {
    margin-bottom: 13px;
  }
`

export const Image = styled.img`
  max-width: 100%;
  display: block;

  @media all and (max-width: 767px) {
    width: 80px;
  }
`

export const Col = styled.div`
  margin-bottom: 0;

  @media all and (min-width: 992px) {
    margin-bottom: ${rem(36)};
  }
`
