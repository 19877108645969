const Link = () => {
  return (
    <svg viewBox="0 0 32 32">
      <path d="M21.6316 19.7624L27.3859 14.0082C28.6412 12.751 29.3326 11.0824 29.3326 9.31102C29.3364 7.54717 28.6356 5.85486 27.3859 4.61007C24.7883 2.01898 20.5836 2.01898 17.9859 4.61007L16.9574 5.63864C16.2131 6.38238 16.2127 7.58865 16.9564 8.33292C17.7001 9.07719 18.9064 9.07762 19.6507 8.33388L20.6793 7.3034C21.4925 6.49629 22.7109 6.25647 23.7693 6.69517C24.8278 7.13387 25.5192 8.16526 25.5231 9.31102C25.5231 9.84435 25.3802 10.6272 24.6926 11.3129L18.9383 17.0672C17.9388 18.0582 16.3643 18.1676 15.2374 17.3244C14.4031 16.6832 13.2071 16.8397 12.5659 17.6739C11.9248 18.5081 12.0812 19.7041 12.9155 20.3453C15.0007 21.944 17.8349 22.1556 20.1345 20.8844C20.6844 20.5835 21.1885 20.2057 21.6316 19.7624Z" />
      <path d="M14.0127 27.3815L15.0432 26.351C15.7869 25.6067 15.7865 24.4004 15.0422 23.6567C14.2979 22.9129 13.0917 22.9134 12.3479 23.6576L11.3194 24.6862C10.5077 25.497 9.28779 25.7393 8.22786 25.3004C7.16792 24.8615 6.47646 23.8277 6.47555 22.6805C6.47024 21.9274 6.77059 21.2044 7.30793 20.6767L13.0603 14.9224C14.0588 13.927 15.6376 13.8182 16.7632 14.6672C17.3028 15.0819 18.0227 15.1773 18.6518 14.9173C19.2808 14.6574 19.7233 14.0816 19.8127 13.4069C19.9021 12.7321 19.6247 12.061 19.0851 11.6462C17.0003 10.0461 14.1653 9.83442 11.866 11.1072C11.3136 11.4081 10.8108 11.7872 10.367 12.2291L4.6146 17.9834C3.35936 19.2405 2.66603 20.9091 2.66603 22.6805C2.66296 24.4446 3.36442 26.1368 4.6146 27.3815C5.86039 28.628 7.5513 29.3271 9.31364 29.3243C11.0761 29.3277 12.7673 28.6285 14.0127 27.3815Z" />
    </svg>
  )
}

export default Link
