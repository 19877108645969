import React from "react"
import CountUp from "react-countup"

interface CounterProps {
  className?: string
  duration?: number
  decimals?: number
  startNum?: number
  end: number
  inView?: boolean
}

export const Counter: React.FC<CounterProps> = ({
  className,
  duration,
  decimals,
  startNum = 1000,
  end,
  inView,
  ...rest
}) => {
  return (
    <CountUp duration={duration} start={inView ? null : startNum} end={end} decimals={decimals} separator="," {...rest}>
      {({ countUpRef, start }) => {
        return (
          <>
            <span className={className} ref={countUpRef} />
          </>
        )
      }}
    </CountUp>
  )
}
