const HelpCircle = () => {
  return (
    <svg viewBox="0 0 32 32">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2535 15.7821C17.0315 16.5021 16.6815 17.0041 16.6815 17.9601V18.5291H14.7205L14.7115 17.8091C14.6635 16.4451 15.2415 15.5931 16.5395 14.8161C17.6855 14.1241 18.0925 13.5841 18.0925 12.6281C18.0925 11.5861 17.2495 10.8281 15.9515 10.8281C14.6445 10.8281 13.8015 11.5951 13.7165 12.8081H11.7555C11.8405 10.6291 13.3095 9.00009 16.0555 9.00009C18.5565 9.00009 20.2425 10.4871 20.2425 12.5421C20.2425 14.0011 19.5035 15.0331 18.2535 15.7821ZM15.8197 23C14.9477 23 14.3517 22.422 14.3517 21.56C14.3517 20.679 14.9477 20.101 15.8197 20.101C16.6907 20.101 17.2777 20.679 17.2777 21.56C17.2777 22.422 16.6907 23 15.8197 23ZM16 4C9.372 4 4 9.372 4 16C4 22.627 9.372 28 16 28C22.627 28 28 22.627 28 16C28 9.372 22.627 4 16 4Z"
      />
    </svg>
  )
}

export default HelpCircle
